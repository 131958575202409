/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoginView from './login.view';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { logInAsync, selectAuth } from './auth.slice';
import { getSettingsAsync, selectSettings } from '../settings/settings.slice';

export interface ILoginContainer {}

const LoginContainer: React.FC<ILoginContainer> = ({}: ILoginContainer) => {
  const auth = useAppSelector(selectAuth);
  const settings = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (auth.isAuthenticated) history.push('/');
  }, [auth.isAuthenticated]);

  useEffect(() => {
    dispatch(getSettingsAsync([]));
  }, []);

  const handleSingnIn = (data: any) => {
    dispatch(logInAsync(data));
  };
  return (
    <LoginView
      handleSingnIn={handleSingnIn}
      generalSettings={settings?.data?.general}
    />
  );
};

export default memo(LoginContainer);
