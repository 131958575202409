import React, { memo, useCallback, useEffect, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  PersonAdd as PersonAddIcon,
  MoreHoriz as MoreHorizIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import { map } from 'lodash';
import { IconButton } from '@material-ui/core';
import styles from './devices.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { IconButton as CustomIconButton } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { emptyUsersViewIcon } from '../../assets/icons';
import { AddDeviceModal, DeleteDeviceModal } from './components';

const tableHeadings = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'deviceType',
    label: 'Type',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'tvAvailable',
    label: 'TV Available',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'rebootStatus',
    label: 'Reboot Status',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'more',
    label: 'More',
    options: {
      filter: true,
      sort: false,
    },
  },
];

interface IDevicesView extends WithStyles<typeof styles> {
  selectDevice: any;
  devicesList: any;
  permissions: any;
  handleAddDevice: (data: any) => void;
  handleDeleteDevice: (id: string) => void;
}

const DevicesView: React.FC<IDevicesView> = ({
  classes,
  selectDevice,
  devicesList,
  permissions,
  handleAddDevice,
  handleDeleteDevice,
}: IDevicesView) => {
  const [openAddDeviceModal, setOpenAddDeviceModal] = useState(false);
  const [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const DeviceOptionList = [
    {
      label: 'Unassign this device',
      onClick: () => setOpenDeleteDeviceModal(true),
      icon: <DeleteOutlinedIcon fontSize="small" />,
    },
  ];

  const handleOpenMoreOption = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedDevice(data);
    setOpenMoreOption(!openMoreOption);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    setAnchorEl(null);
    setOpenMoreOption(false);
  };
  const renderTableRow = useCallback(() => {
    return map(devicesList, (row) => {
      return {
        ...row,
        name: row.deviceName,
        tvAvailable: row.tvAvailable ? 'true' : 'false',
        more: (
          <IconButton
            id="more-option-in-devices"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
      };
    });
  }, [devicesList]);

  const renderAddDeviceButton = () => {
    return (
      <div>
        <CustomIconButton
          customStyles={classes.button}
          onClick={() => setOpenAddDeviceModal(true)}
          label="Add Device"
          id="add-device-button"
          icon={<PersonAddIcon />}
          variant="contained"
        />
      </div>
    );
  };
  if (!devicesList) {
    return (
      <NoDataLayout image={emptyUsersViewIcon} heading="Add your first device">
        {renderAddDeviceButton()}
        <AddDeviceModal
          selectDevice={selectDevice}
          open={openAddDeviceModal}
          handleClose={() => setOpenAddDeviceModal(false)}
          handleAddDevice={handleAddDevice}
        />
      </NoDataLayout>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="devices-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          Devices
        </Typography>
        <div className={classes.buttonWrapper}>{renderAddDeviceButton()}</div>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            customStyles={classes.tableWrapper}
            id="devices-list-table"
            title={
              <div id="total-devices">{`Total devices: ${devicesList.length}`}</div>
            }
            columns={tableHeadings}
            data={renderTableRow()}
            pagination={false}
          />
        </Card>
      </div>
      <AddDeviceModal
        selectDevice={selectDevice}
        open={openAddDeviceModal}
        handleClose={() => setOpenAddDeviceModal(false)}
        handleAddDevice={handleAddDevice}
      />
      <DeleteDeviceModal
        selectDevice={selectDevice}
        open={openDeleteDeviceModal}
        handleClose={() => setOpenDeleteDeviceModal(false)}
        selectedDevice={selectedDevice}
        handleDeleteDevice={handleDeleteDevice}
      />
      <Dropdown
        id="device-more-option-button"
        open={Boolean(selectedDevice) && openMoreOption}
        anchorRef={anchorEl}
        handleClose={handleCloseMoreOption}
        itemList={DeviceOptionList}
      />
    </div>
  );
};

export default memo(withStyles(styles)(DevicesView));
