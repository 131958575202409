import React, { memo } from 'react';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from 'mui-datatables';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(1),
      '& .MuiToolbar-gutters': {
        paddingLeft: theme.spacing(2),
      },
      '& .MuiTableCell-body': {
        color: theme.palette.secondary.main,
      },
    },
  });
};

export interface ITableProps extends WithStyles<typeof styles> {
  id: string;
  columns: MUIDataTableColumnDef[];
  data: Array<any | number[] | string[]>;
  options?: MUIDataTableOptions | undefined;
  title: string | React.ReactNode;
  pagination?: boolean;
  customStyles?: any;
}

const Table = ({
  id,
  classes,
  columns,
  data,
  options,
  title,
  pagination = true,
  customStyles,
}: ITableProps) => {
  return (
    <div
      id={id}
      className={clsx(classes.container, customStyles && customStyles)}
    >
      <MUIDataTable
        title={title}
        columns={columns}
        data={data}
        options={{
          filter: false,
          download: false,
          print: false,
          selectableRows: 'none',
          elevation: 0,
          viewColumns: false,
          pagination,
          ...options,
        }}
      />
    </div>
  );
};

export default memo(withStyles(styles)(Table));
