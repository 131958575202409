import api from '../../app/axios';

export const getGroupsApi = () => {
  return api.get('group/read');
};

export const addGroupApi = (data: any) => {
  return api.post(`group/create`, data);
};

export const fetchAllMembersApi = () => {
  return api.get('users/getmembers');
};

export const deleteGroupApi = (id: string) => {
  return api.delete(`group/destroy/${id}`);
};

export const editGroupApi = (data: any) => {
  return api.post(`group/update`, data);
};
