import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  getSettingsApi,
  updateSettingsApi,
  addFAQApi,
  updateFAQApi,
  deleteFAQApi,
  uploadSystemLogoApi,
  uploadProfilePictureApi,
} from './settings.api';
import { actions as appActions } from '../app/app.slice';

export interface ISettingsState {
  data: any;
  loading: boolean;
  error: any;
}

const initialState: ISettingsState = {
  data: null,
  loading: false,
  error: null,
};

export const getSettingsAsync = createAsyncThunk(
  'settings/fetch',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await getSettingsApi();
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateSettingsAsync = createAsyncThunk(
  'settings/update',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateSettingsApi(data);
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully update settings',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const addFAQAsync = createAsyncThunk(
  'settings/faq/add',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await addFAQApi(data);
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully add new FAQ',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateFAQAsync = createAsyncThunk(
  'settings/faq/update',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateFAQApi(data);
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully update FAQ',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteFAQAsync = createAsyncThunk(
  'settings/faq/delete',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteFAQApi(id);
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully delete FAQ',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const uploadSystemLogoAsync = createAsyncThunk(
  'settings/uploadLogo',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await uploadSystemLogoApi(data);
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'Logo successfully uploaded',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const uploadProfilePictureAsync = createAsyncThunk(
  'settings/uploadProfilePicture',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await uploadProfilePictureApi(data);
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'Profile picture successfully uploaded',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetSettings(state) {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    // get settings
    builder.addCase(getSettingsAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSettingsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(getSettingsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // update settings
    builder.addCase(updateSettingsAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateSettingsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(updateSettingsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // add FAQ
    builder.addCase(addFAQAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addFAQAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(addFAQAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // update FAQ
    builder.addCase(updateFAQAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateFAQAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(updateFAQAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // delete  FAQ
    builder.addCase(deleteFAQAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteFAQAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(deleteFAQAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = settingsSlice;

export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;
