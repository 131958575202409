import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    topWrapper: {
      width: '100%',
      marginBottom: '2vh',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    heading: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '2vh',
      },
    },
    buttonWrapper: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    tableContainer: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    gridItem: {
      height: '100%',
      overflow: 'auto',
    },
    table: {
      paddingTop: 0,
      paddingBottom: 0,
      height: '100%',
      overflow: 'auto',
    },
    tableActionButton: {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        textAlign: 'start',
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
      '& .MuiButton-label': {
        display: 'block',
      },
    },
    button: {
      marginLeft: 0,
      marginRight: 0,
      '& .MuiButton-contained': {
        width: 'max-content',
      },
    },
    bottomWrapper: {
      position: 'relative',
      flexGrow: 1,
    },
    cardLeft: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
      },
    },
    tableWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
    },
  });
};

export default styles;
