/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addMemberAsync,
  deleteMembersAsync,
  fetchAllMembersAsync,
  resetMembersPasswordAsync,
  selectMembers,
} from './members.slice';
import { selectAuth } from '../auth/auth.slice';
import MembersView from './members.view';

export interface IMembersContainer {}

const MembersContainer: React.FC<IMembersContainer> = ({}: IMembersContainer) => {
  const members = useAppSelector(selectMembers);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllMembersAsync(null));
  }, []);

  const handleAddMember = (data: any) => {
    const { email, name, contactNumber } = data;
    dispatch(addMemberAsync({ email, name, contactNumber }));
  };

  const handleDeleteMember = (id: string) => {
    dispatch(deleteMembersAsync(id));
  };

  const handleRestMemberPassword = (data: any) => {
    navigator.clipboard.writeText(data.password);
    dispatch(resetMembersPasswordAsync(data));
  };

  return (
    <MembersView
      selectMember={members}
      membersList={members.list}
      permissions={auth.permissions}
      handleAddMember={handleAddMember}
      handleDeleteMember={handleDeleteMember}
      handleRestMemberPassword={handleRestMemberPassword}
    />
  );
};

export default memo(MembersContainer);
