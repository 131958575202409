/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { memo } from 'react';
import { Theme, TableRow, TableCell } from '@material-ui/core';
import clsx from 'clsx';
import MUIDataTable, {
  ExpandButton,
  MUIDataTableColumnDef,
  MUIDataTableIsRowCheck,
  MUIDataTableOptions,
} from 'mui-datatables';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Datatable } from '../Datatable';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(1),
      '& .MuiToolbar-gutters': {
        paddingLeft: theme.spacing(2),
      },
      '& .MuiTableCell-body': {
        color: theme.palette.secondary.main,
      },
    },
  });
};

export interface ITableProps extends WithStyles<typeof styles> {
  id: string;
  columns: MUIDataTableColumnDef[];
  data: Array<any | number[] | string[]>;
  options?: MUIDataTableOptions | undefined;
  title: string | React.ReactNode;
  pagination?: boolean;
  customStyles?: any;
}

const CollapsibleTable = ({
  id,
  classes,
  columns,
  data,
  options,
  title,
  pagination = true,
  customStyles,
}: ITableProps) => {
  const components = {
    ExpandButton(props: any) {
      // eslint-disable-next-line react/destructuring-assignment
      // if (props.dataIndex === 3 || props.dataIndex === 4)
      //   return <div style={{ width: '24px' }} />;
      return <ExpandButton {...props} />;
    },
  };

  return (
    <div
      id={id}
      className={clsx(classes.container, customStyles && customStyles)}
    >
      <MUIDataTable
        title={title}
        columns={columns}
        data={data}
        components={components}
        options={{
          download: false,
          print: false,
          selectableRows: 'none',
          elevation: 0,
          viewColumns: false,
          pagination,
          filter: true,
          filterType: 'dropdown',
          responsive: 'standard',
          expandableRows: true,
          expandableRowsHeader: false,
          expandableRowsOnClick: true,
          isRowExpandable: (
            dataIndex: number,
            expandedRows: MUIDataTableIsRowCheck | undefined
          ) => {
            // if (dataIndex === 3 || dataIndex === 4) return false;

            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            if (
              expandedRows!.data?.length > 4 &&
              expandedRows!.data?.filter((d: any) => d.dataIndex === dataIndex)
                .length === 0
            )
              return false;
            return true;
          },
          // rowsExpanded: [0, 1],
          renderExpandableRow: (rowData: any, rowMeta: any) => {
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  {rowData[0].props.children[1] ? (
                    <Datatable
                      data={JSON.parse(rowData[0].props.children[1])}
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
            );
          },
          // onRowExpansionChange: (
          //   curExpanded: any,
          //   allExpanded: any,
          //   rowsExpanded: any
          // ) => {
          //   console.log(curExpanded, allExpanded, rowsExpanded);
          // },
          ...options,
        }}
      />
    </div>
  );
};

export default memo(withStyles(styles)(CollapsibleTable));
