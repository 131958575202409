import api from '../../app/axios';

export const fetchAllMeetingsApi = () => {
  return api.get('meetings');
};

export const scheduleMeetingApi = (data: any) => {
  return api.post('meetings', data);
};

export const removeMeetingApi = (id: string) => {
  return api.delete(`meetings/${id}`);
};
