import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    topWrapper: {
      width: '100%',
      marginBottom: '2vh',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    heading: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '2vh',
      },
    },
    bottomWrapper: {
      position: 'relative',
      flexGrow: 1,
    },
    cardLeft: {
      marginRight: theme.spacing(2),
      height: 'auto',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
      },
    },
    tableContainer: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        overflow: 'unset',
        position: 'relative',
      },
    },
    gridItem: {
      height: '100%',
      overflow: 'auto',
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
      },
    },
    title: {
      borderBottom: '1px solid #00000038',
      width: '100%',
      padding: '16px 0',
    },
    tableWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
    },
    table: {
      paddingTop: 0,
      paddingBottom: 0,
      height: '100%',
      overflow: 'auto',
    },
    timeWrapper: {
      marginTop: theme.spacing(2),
    },
  });
};

export default styles;
