import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import {
  validateContactNumber,
  validateEmail,
} from '../../../helper/validation';
import { TextField } from '../../../components/TextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IAddCustomerModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleAddCustomer: (data: any) => void;
  selectCustomer: any;
}

const AddCustomerModal = ({
  classes,
  open,
  handleClose,
  handleAddCustomer,
  selectCustomer,
}: IAddCustomerModalProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState({ error: false, value: '' });
  const [contactNumber, setContactNumber] = useState({
    error: false,
    value: '',
  });

  useEffect(() => {
    handleClose();
  }, [selectCustomer.loading]);

  const _validate = () => {
    let isValidate = true;
    if (!email.value || email.error) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }
    if (!contactNumber.value || contactNumber.error) {
      isValidate = false;
      setContactNumber({ ...contactNumber, error: true });
    }

    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const customerData = {
        name,
        email: email.value,
        contactNumber: contactNumber.value,
      };
      handleAddCustomer(customerData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName(e.target.value);
  };

  const emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  const contactNumberOnChange = (e: { target: { value: string } }) => {
    const error = validateContactNumber(e.target.value);
    setContactNumber({ value: e.target.value, error });
  };

  return (
    <ActionModal
      modalTitle="Add Customer"
      modalDescription="Make Customer to manage meetings"
      id="add-customer-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
    >
      <TextField placeholder="Name" onChange={nameOnChange} />
      <TextField
        id="add-customer-email"
        error={email.error}
        placeholder="Email"
        required
        onChange={emailOnChange}
        helperText={email.error && 'Please insert a valid email address.'}
      />
      <TextField
        id="add-customer-contact-number"
        error={contactNumber.error}
        placeholder="Contact Number"
        required
        onChange={contactNumberOnChange}
        helperText={
          contactNumber.error && 'Please insert a valid contact number.'
        }
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(AddCustomerModal));
