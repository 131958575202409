/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import {
  Theme,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core';
import clsx from 'clsx';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(1),
      '& .MuiToolbar-gutters': {
        paddingLeft: theme.spacing(2),
      },
      '& .MuiTableCell-body': {
        color: theme.palette.secondary.main,
      },
    },
  });
};

export interface RowData {
  name: string;
  created: string;
  destroyed: string;
  participants: number;
  duration: number;
}

export interface ITableProps extends WithStyles<typeof styles> {
  data: RowData[];
}

const Datatable = ({ data }: ITableProps) => {
  const headers = ['name', 'created', 'destroyed', 'participants', 'duration'];

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell align="right" key={header}>
                {header.toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((emp, index) => (
            <TableRow key={index}>
              {headers.map((header) => (
                <TableCell align="right" key={header}>
                  {(header === 'created' || header === 'destroyed') &&
                  emp[header as keyof RowData] == null
                    ? 'inprogess'
                    : header === 'created' || header === 'destroyed'
                    ? moment(new Date(emp[header as keyof RowData])).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )
                    : emp[header as keyof RowData]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default memo(withStyles(styles)(Datatable));
