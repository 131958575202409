import React, { memo, useCallback, useEffect, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  PersonAdd as PersonAddIcon,
  MoreHoriz as MoreHorizIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import { map } from 'lodash';
import { IconButton } from '@material-ui/core';
import styles from './members.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { IconButton as CustomIconButton } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { emptyUsersViewIcon } from '../../assets/icons';
import {
  AddMemberModal,
  ChangePasswordModal,
  DeleteMemberModal,
} from './components';

const tableHeadings = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'contactNumber',
    label: 'Contact Number',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'more',
    label: 'More',
    options: {
      filter: true,
      sort: false,
    },
  },
];

interface IMembersView extends WithStyles<typeof styles> {
  selectMember: any;
  membersList: any;
  permissions: any;
  handleAddMember: (data: any) => void;
  handleDeleteMember: (id: string) => void;
  handleRestMemberPassword: (data: any) => void;
}

const MembersView: React.FC<IMembersView> = ({
  classes,
  selectMember,
  membersList,
  permissions,
  handleAddMember,
  handleDeleteMember,
  handleRestMemberPassword,
}: IMembersView) => {
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMember, setSelectedMember] = useState(null);

  const MemberOptionList = [
    {
      label: 'Delete this member',
      onClick: () => setOpenDeleteMemberModal(true),
      icon: <DeleteOutlinedIcon fontSize="small" />,
    },
    {
      label: 'Change Password',
      onClick: () => setOpenResetPasswordModal(true),
      icon: <VisibilityOutlinedIcon fontSize="small" />,
    },
  ];

  const handleOpenMoreOption = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedMember(data);
    setOpenMoreOption(!openMoreOption);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    setAnchorEl(null);
    setOpenMoreOption(false);
  };
  const renderTableRow = useCallback(() => {
    return map(membersList, (row) => {
      return {
        ...row,
        name: row.profile.name,
        contactNumber: row.profile.contactNumber,
        status: row.isVerified ? 'active' : 'pending',
        more: (
          <IconButton
            id="more-option-in-members"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
      };
    });
  }, [membersList]);

  const renderAddMemberButton = () => {
    return (
      <div>
        <CustomIconButton
          customStyles={classes.button}
          onClick={() => setOpenAddMemberModal(true)}
          label="Add Member"
          id="add-member-button"
          icon={<PersonAddIcon />}
          variant="contained"
        />
      </div>
    );
  };
  if (!membersList) {
    return (
      <NoDataLayout image={emptyUsersViewIcon} heading="Add your first member">
        {renderAddMemberButton()}
        <AddMemberModal
          selectMember={selectMember}
          open={openAddMemberModal}
          handleClose={() => setOpenAddMemberModal(false)}
          handleAddMember={handleAddMember}
        />
      </NoDataLayout>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="members-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          Members
        </Typography>
        <div className={classes.buttonWrapper}>{renderAddMemberButton()}</div>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            customStyles={classes.tableWrapper}
            id="members-list-table"
            title={
              <div id="total-members">{`Total members: ${membersList.length}`}</div>
            }
            columns={tableHeadings}
            data={renderTableRow()}
            pagination={false}
          />
        </Card>
      </div>
      <AddMemberModal
        selectMember={selectMember}
        open={openAddMemberModal}
        handleClose={() => setOpenAddMemberModal(false)}
        handleAddMember={handleAddMember}
      />
      <DeleteMemberModal
        selectMember={selectMember}
        open={openDeleteMemberModal}
        handleClose={() => setOpenDeleteMemberModal(false)}
        selectedMember={selectedMember}
        handleDeleteMember={handleDeleteMember}
      />
      <ChangePasswordModal
        selectMember={selectMember}
        open={openResetPasswordModal}
        handleClose={() => setOpenResetPasswordModal(false)}
        selectedMember={selectedMember}
        handleRestMemberPassword={handleRestMemberPassword}
      />
      <Dropdown
        id="member-more-option-button"
        open={Boolean(selectedMember) && openMoreOption}
        anchorRef={anchorEl}
        handleClose={handleCloseMoreOption}
        itemList={MemberOptionList}
      />
    </div>
  );
};

export default memo(withStyles(styles)(MembersView));
