import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  addUserApi,
  deleteUserApi,
  fetchAllUsersApi,
  resetUserPasswordApi,
} from './users.api';
import { actions as appActions } from '../app/app.slice';

export interface IUsersState {
  list: Array<any>;
  addUser: any;
  deleteUser: any;
  resetUserPassword: any;
  addCustomer: any;
  addMember: any;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IUsersState = {
  list: [],
  addUser: null,
  deleteUser: null,
  resetUserPassword: null,
  addCustomer: null,
  addMember: null,
  loading: false,
  success: false,
  error: null,
};

export const fetchAllUsersAsync = createAsyncThunk(
  'users/fetchAll',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllUsersApi();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addUserAsync = createAsyncThunk(
  'users/addUser',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await addUserApi(data);
      dispatch(fetchAllUsersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully create user',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteUsersAsync = createAsyncThunk(
  'users/deleteUser',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteUserApi(data);
      dispatch(fetchAllUsersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully delete user',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const resetUsersPasswordAsync = createAsyncThunk(
  'users/resetUserPassword',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await resetUserPasswordApi(data);
      dispatch(fetchAllUsersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully reset password',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch all users
    builder.addCase(fetchAllUsersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUsersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
    });
    builder.addCase(fetchAllUsersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add new users
    builder.addCase(addUserAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.addUser = action.payload;
    });
    builder.addCase(addUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete users
    builder.addCase(deleteUsersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteUsersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteUser = action.payload;
    });
    builder.addCase(deleteUsersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Reset user password
    builder.addCase(resetUsersPasswordAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetUsersPasswordAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetUserPassword = action.payload;
    });
    builder.addCase(resetUsersPasswordAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = usersSlice;

export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;
