import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    topWrapper: {
      width: '100%',
      marginBottom: '2vh',
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonWrapper: {
      display: 'flex',
    },
    button: {
      marginLeft: 0,
      marginRight: 0,
      '& .MuiButton-contained': {
        width: 'max-content',
      },
    },
    bottomWrapper: {
      position: 'relative',
      flexGrow: 1,
    },
    card: {},
    tableWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
    },
  });
};

export default styles;
