import api from '../../app/axios';

export const fetchAllCustomersApi = () => {
  return api.get('users/getcustomers');
};

export const addCustomerApi = (data: any) => {
  return api.post(`users/createcustomer`, data);
};

export const deleteCustomerApi = (id: string) => {
  return api.delete(`users/customer/${id}`);
};

export const resetCustomerPasswordApi = (data: any) => {
  const { _id, password } = data;
  return api.post(`users/customer/${_id}/password`, { password });
};

export const promoteCustomerApi = (id: string) => {
  return api.post(`users/promotecustomer/${id}`);
};

export const demoteSubadminApi = (id: string) => {
  return api.post(`users/demotesubadmin/${id}`);
};
