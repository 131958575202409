import React, { memo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { Theme, TextField } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: '100%',
    },
  });
};

export interface ITimePickerProps extends WithStyles<typeof styles> {
  id: string;
  customStyles?: any;
  value?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  label?: React.ReactNode;
  type?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
}

const TimePicker = ({
  classes,
  id,
  customStyles,
  onChange,
  value,
  label,
  placeholder,
  helperText = '',
  error = false,
  type = 'time',
}: ITimePickerProps) => {
  return (
    <form
      className={clsx(classes.container, customStyles && customStyles)}
      noValidate
    >
      <TextField
        className={classes.textField}
        id={id}
        defaultValue={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
        label={label}
        value={value}
        onChange={onChange}
        type={type}
        helperText={error && helperText}
        placeholder={placeholder}
        error={error}
        InputLabelProps={{
          shrink: true,
        }}
        // inputProps={{
        //   step: 300, // 5 min
        // }}
      />
    </form>
  );
};

export default memo(withStyles(styles)(TimePicker));
