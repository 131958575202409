import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  addMemberApi,
  deleteMemberApi,
  fetchAllMembersApi,
  resetMemberPasswordApi,
} from './members.api';
import { actions as appActions } from '../app/app.slice';

export interface IMembersState {
  list: Array<any>;
  deleteMember: any;
  resetMemberPassword: any;
  addMember: any;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IMembersState = {
  list: [],
  addMember: null,
  deleteMember: null,
  resetMemberPassword: null,
  loading: false,
  success: false,
  error: null,
};

export const fetchAllMembersAsync = createAsyncThunk(
  'members/fetchAll',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllMembersApi();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addMemberAsync = createAsyncThunk(
  'members/addMember',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await addMemberApi(data);
      dispatch(fetchAllMembersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully create member',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteMembersAsync = createAsyncThunk(
  'members/deleteMember',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteMemberApi(data);
      dispatch(fetchAllMembersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully delete member',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const resetMembersPasswordAsync = createAsyncThunk(
  'members/resetMemberPassword',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await resetMemberPasswordApi(data);
      dispatch(fetchAllMembersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully reset password',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch all members
    builder.addCase(fetchAllMembersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMembersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
    });
    builder.addCase(fetchAllMembersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add new members
    builder.addCase(addMemberAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addMemberAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.addMember = action.payload;
    });
    builder.addCase(addMemberAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete members
    builder.addCase(deleteMembersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteMembersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteMember = action.payload;
    });
    builder.addCase(deleteMembersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Reset member password
    builder.addCase(resetMembersPasswordAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetMembersPasswordAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetMemberPassword = action.payload;
    });
    builder.addCase(resetMembersPasswordAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = membersSlice;

export const selectMembers = (state: RootState) => state.members;

export default membersSlice.reducer;
