import api from '../../app/axios';

export const fetchAllMembersApi = () => {
  return api.get('users/getmembers');
};

export const addMemberApi = (data: any) => {
  return api.post(`users/createmember`, data);
};

export const deleteMemberApi = (id: string) => {
  return api.delete(`users/member/${id}`);
};

export const resetMemberPasswordApi = (data: any) => {
  const { _id, password } = data;
  return api.post(`users/member/${_id}/password`, { password });
};
