/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MeetingsView from './meetings.view';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { generateMeetingUrl } from '../../helper/conference';
import {
  selectMeetings,
  fetchAllMeetingsAsync,
  removeMeetingAsync,
} from './meetings.slice';
import { actions as scheduleMeetingActions } from '../scheduleMeeting/scheduleMeeting.slice';
import { selectAuth } from '../auth/auth.slice';
// import { ScheduleMeetingModal, InstantMeetingmodal } from '../scheduleMeeting';

export interface IMeetingsContainer {}

const MeetingsContainer: React.FC<IMeetingsContainer> = ({}: IMeetingsContainer) => {
  const meetings = useAppSelector(selectMeetings);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [recentMeetings, setRecentMeetings] = useState<Array<any>>([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState<Array<any>>([]);

  useEffect(() => {
    dispatch(fetchAllMeetingsAsync(null));
  }, []);

  useEffect(() => {
    const now = new Date().toISOString();
    const past = meetings?.list
      ?.filter(({ beginTime }) => beginTime <= now)
      .sort((a, b) => (a.beginTime - b.beginTime ? 1 : -1));
    const future = meetings?.list
      ?.filter(({ beginTime }) => beginTime > now)
      .sort((a, b) => (a.beginTime - b.beginTime ? -1 : 1));

    setUpcomingMeetings(future);
    setRecentMeetings(past);
  }, [meetings.list]);

  const _joinToMeetionButtonClick = (data: any) => {
    const url = generateMeetingUrl(data?.location, false);
    history.push(url);
  };

  const _removeMeeting = (id: string) => {
    dispatch(removeMeetingAsync(id));
  };

  const _openEditMeetionModal = (data: any) => {
    dispatch(scheduleMeetingActions.openEditMeetingModal(data));
  };

  return (
    <>
      <MeetingsView
        user={auth?.user}
        recentMeetings={recentMeetings || []}
        upcomingMeetings={upcomingMeetings || []}
        joinToMeetionButtonClick={_joinToMeetionButtonClick}
        removeMeeting={_removeMeeting}
        openEditMeetionModal={_openEditMeetionModal}
      />
      {/* <ScheduleMeetingModal />
      <InstantMeetingmodal /> */}
    </>
  );
};

export default memo(MeetingsContainer);
