/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { TextField, PasswordTextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { Typography } from '../../components/Typography';
import { validateEmail, validatePassword } from '../../helper/validation';
import styles from './auth.styles';

import { Logo as DefaultLogo } from '../../assets/icons';

// interface IGeneralSettings {

// }
interface ILoginView extends WithStyles<typeof styles> {
  handleSingnIn: Function;
  generalSettings?: any;
}

const LoginView: React.FC<ILoginView> = ({
  classes,
  handleSingnIn,
  generalSettings,
}: ILoginView) => {
  const [email, setEmail] = useState({ error: false, value: '' });
  const [password, setPassword] = useState({ error: false, value: '' });

  const _validate = () => {
    let isValidate = true;

    if (email.error || password.error) isValidate = false;
    if (!email.value) {
      isValidate = false;
      setEmail({ ...email, error: true });
    }

    if (!password.value) {
      isValidate = false;
      setPassword({ ...password, error: true });
    }

    return isValidate;
  };

  const _handleSignInButton = () => {
    if (_validate()) {
      handleSingnIn({ email: email.value, password: password.value });
    }
  };

  const _handleEnterPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      _handleSignInButton();
    }
  };

  const _emailOnChange = (e: { target: { value: string } }) => {
    const error = validateEmail(e.target.value);
    setEmail({ value: e.target.value, error });
  };

  const _passwordOnChange = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setPassword({ value: e.target.value, error });
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={7} className={classes.leftGrid}>
        <div>
          {/* <Typography component="h3" variant="h4" className={classes.heading}>
            Use bellow credentials for login to MM system.
          </Typography>
          <br />
          <Typography component="h4" variant="h5" className={classes.heading}>
            Username - testdemo@meetrix.io
            <br />
            Password - test@123
          </Typography>
          <br />
          <Typography component="h4" variant="h5" className={classes.heading}>
            Note - Please kindly note that your data will not be saved in the
            system.
          </Typography> */}
        </div>
      </Grid>
      <Grid item xs={12} sm={5} className={classes.rightGrid}>
        <div className={classes.formWrapper}>
          <img
            src={generalSettings?.logo || DefaultLogo}
            alt={`${generalSettings?.companyName}-logo` || 'meetrix-logo'}
            className={classes.logo}
          />
          <Typography component="h1" variant="h5" className={classes.heading}>
            Sign in to {generalSettings?.companyName || 'Continue'}
          </Typography>
          <TextField
            id="sign-in-email"
            label="Email"
            onChange={_emailOnChange}
            error={email.error}
            helperText={email.error && 'Please insert a valid email address.'}
            onKeyDown={_handleEnterPress}
          />
          <PasswordTextField
            id="sign-in-password"
            label="Password"
            onChange={_passwordOnChange}
            error={password.error}
            helperText={password.error && 'Please insert a valid password'}
            onKeyDown={_handleEnterPress}
          />
          <Button
            id="sign-in-button"
            align="right"
            variant="contained"
            label="Sign in"
            onClick={_handleSignInButton}
            customStyles={classes.buttonMargin}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default memo(withStyles(styles)(LoginView));
