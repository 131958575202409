/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { memo } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styles from './invitationExpired.styles';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import { Logo as DefaultLogo } from '../../assets/icons';
import { selectSettings } from '../settings/settings.slice';
import { useAppSelector } from '../../app/hooks';

type IInvitationExpiredView = WithStyles<typeof styles>;

const InvitationExpiredView: React.FC<IInvitationExpiredView> = ({
  classes,
}: IInvitationExpiredView) => {
  const settings = useAppSelector(selectSettings);
  const history = useHistory();

  const _handleOnClickButton = () => {
    history.push('/signin');
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={7} className={classes.leftGrid} />
      <Grid item xs={12} sm={5} className={classes.rightGrid}>
        <div className={classes.formWrapper}>
          <img
            src={settings?.data?.general?.logo || DefaultLogo}
            alt={
              `${settings?.data?.general?.companyName}-logo` || 'meetrix-logo'
            }
            className={classes.logo}
          />
          <Typography component="h1" variant="h5" className={classes.heading}>
            Invitation expired - Please request a new invite.
          </Typography>
          <div className={classes.subTextWrapper}>
            <Divider className={classes.subTextDivider} />
            <Typography
              component="p"
              variant="body1"
              className={classes.subText}
            >
              Or you still can sign up
            </Typography>
          </div>
          <Button
            id="sign-in-button"
            align="center"
            variant="contained"
            label="Sign in"
            onClick={_handleOnClickButton}
            customStyles={classes.buttonMargin}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default memo(withStyles(styles)(InvitationExpiredView));
