/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MeetingRoomView from './meetingRoom.view';
import { actions as scheduleMeetingAction } from '../scheduleMeeting/scheduleMeeting.slice';
import {
  getMeetingTokenAsync,
  selectMeetingRoom,
  actions,
} from './meetingRoom.slice';
import { selectAuth } from '../auth/auth.slice';
import { selectSettings } from '../settings/settings.slice';

export interface IMeetingRoomContainer {}

const MeetingRoomContainer: React.FC<IMeetingRoomContainer> = ({}: IMeetingRoomContainer) => {
  const dispatch = useAppDispatch();
  const meetingRoom = useAppSelector(selectMeetingRoom);
  const auth = useAppSelector(selectAuth);
  const settings = useAppSelector(selectSettings);

  const history = useHistory();
  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    const pathnameArray = history.location.pathname.split('/');
    const room =
      pathnameArray.length > 0 ? pathnameArray[pathnameArray.length - 1] : '';
    setRoomName(room);
  }, [history.location.pathname]);

  const _backToHomeButtonOnClick = () => {
    dispatch(actions.resetJwtToken());
    history.push('/');
  };

  const _resetScheduleMeeting = () => {
    dispatch(scheduleMeetingAction.resetScheduleMeeting());
  };

  const _getJwtTokenButtonOnClick = (data: any) => {
    dispatch(getMeetingTokenAsync(data));
  };
  return (
    <MeetingRoomView
      location={roomName}
      jwtToken={meetingRoom?.jwtToken}
      user={auth?.user}
      backToHomeButtonOnClick={_backToHomeButtonOnClick}
      getJwtTokenButtonOnClick={_getJwtTokenButtonOnClick}
      showScreenappForModerator={settings?.data?.screenApp?.recForModerator}
      showScreenappForParticipants={
        settings?.data?.screenApp?.recForParticipant
      }
    />
  );
};

export default memo(MeetingRoomContainer);
