/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addGroupAsync,
  deleteGroupAsync,
  editGroupAsync,
  getGroupsAsync,
  selectGroups,
} from './groups.slice';
import { selectAuth } from '../auth/auth.slice';
import GroupsView from './groups.view';

export interface IGroupsContainer {}

const GroupsContainer: React.FC<IGroupsContainer> = ({}: IGroupsContainer) => {
  const groups = useAppSelector(selectGroups);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGroupsAsync(null));
  }, []);

  const handleAddGroup = (data: any) => {
    dispatch(addGroupAsync(data));
  };

  const handleDeleteGroup = (id: string) => {
    dispatch(deleteGroupAsync(id));
  };

  const handleEditGroup = (data: any) => {
    dispatch(editGroupAsync(data));
  };

  return (
    <GroupsView
      selectGroup={groups}
      groupsList={groups.list}
      permissions={auth.permissions}
      handleAddGroup={handleAddGroup}
      handleDeleteGroup={handleDeleteGroup}
      handleEditGroup={handleEditGroup}
    />
  );
};

export default memo(GroupsContainer);
