import api from '../../app/axios';

export const getSettingsApi = () => {
  return api.get('settings');
};

export const updateSettingsApi = (data: any) => {
  const { setting, category } = data;
  return api.post(`settings/${category}`, { ...setting });
};

export const addFAQApi = (data: any) => {
  return api.post(`settings/faq`, data);
};

export const updateFAQApi = (data: any) => {
  const { faq, id } = data;
  return api.post(`settings/faq/${id}`, { ...faq });
};

export const deleteFAQApi = (id: string) => {
  return api.delete(`settings/faq/${id}`);
};

export const uploadProfilePictureApi = (data: any) => {
  const { file, type } = data;
  return api.post(`upload/profilepicture?type=${type}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadSystemLogoApi = (data: any) => {
  const { file, type } = data;
  return api.post(`upload/systemlogo?type=${type}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
