/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/ban-types */
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchAllMembersAsync, selectGroups } from '../groups.slice';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IAddGroupModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleAddGroup: (data: any) => void;
  selectGroup: any;
}

const AddGroupModal = ({
  classes,
  open,
  handleClose,
  handleAddGroup,
  selectGroup,
}: IAddGroupModalProps) => {
  const groups = useAppSelector(selectGroups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    handleClose();
    setName({
      error: false,
      value: '',
    });
    setDescription({
      error: false,
      value: '',
    });
    setMemberIds({
      error: false,
      value: [],
    });
  }, [selectGroup.loading]);

  useEffect(() => {
    dispatch(fetchAllMembersAsync(null));
  }, []);

  const [name, setName] = useState({
    error: false,
    value: '',
  });
  const [description, setDescription] = useState({
    error: false,
    value: '',
  });

  interface ImemberIdState {
    error: boolean;
    value: any[];
  }
  const [memberIds, setMemberIds] = useState<ImemberIdState>({
    error: false,
    value: [],
  });

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (!description.value || description.error) {
      isValidate = false;
      setDescription({ ...description, error: true });
    }
    if (memberIds.value.length === 0 || memberIds.error) {
      isValidate = false;
      setMemberIds({ ...memberIds, error: true });
    }
    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const memberIdsArr = [];
      for (const member of memberIds.value) {
        memberIdsArr.push(member._id);
      }

      const groupData = {
        name: name.value,
        description: description.value,
        members: memberIdsArr,
      };
      handleAddGroup(groupData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const descriptionOnChange = (e: { target: { value: string } }) => {
    setDescription({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const handleChange = (
    event: ChangeEvent<{}>,
    value: any[],
    reason: AutocompleteChangeReason
  ) => {
    setMemberIds({
      value,
      error: value.length === 0,
    });
  };

  return (
    <ActionModal
      modalTitle="Add Group"
      modalDescription="Make Group to manage meetings"
      id="add-group-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
    >
      <TextField
        id="add-group-name"
        error={name.error}
        placeholder="Name"
        onChange={nameOnChange}
        required
        helperText={name.error && 'Please insert a name.'}
      />
      <TextField
        id="add-group-description"
        error={description.error}
        placeholder="Description"
        onChange={descriptionOnChange}
        required
        helperText={description.error && 'Please insert a valid description.'}
      />
      <Autocomplete
        multiple
        limitTags={2}
        id="add-group-members"
        options={groups.memberList}
        getOptionLabel={(option) => option.profile.name}
        // defaultValue={[groups.memberList[3]]}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Members"
            placeholder="Select at least one Member"
          />
        )}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(AddGroupModal));
