import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Theme,
  ModalProps,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { MaxWidth } from '../types';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      // margin: theme.spacing(1),
      '& .MuiPaper-rounded': {
        borderRadius: '12px',
      },
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(3, 2),
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
    },
    title: {
      paddingLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
    symbol: {
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
    selectedItem: {
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
    closeButton: {
      position: 'absolute',
      right: '0.5em',
      top: '0.5em',
    },
    description: {
      margin: theme.spacing(0, 3),
    },
    dialogContent: {
      paddingRight: theme.spacing(5),
      paddingBottom: 0,
    },
    dialogActions: {
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(4),
    },
  });
};

export interface IModalProps extends WithStyles<DialogProps & typeof styles> {
  id?: string;
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  modalTitle?: string;
  selectedItem?: string;
  modalDescription?: string;
  actionButtons?: ReactNode;
  fullWidth?: boolean;
  maxWidth?: MaxWidth;
  disableBackdropClick?: boolean;
}

export const BasicModal: React.FC<IModalProps> = ({
  classes,
  id,
  open,
  modalTitle,
  selectedItem,
  modalDescription,
  children,
  actionButtons,
  handleClose,
  fullWidth = true,
  maxWidth = 'sm',
  disableBackdropClick = true,
}: IModalProps) => {
  return (
    <Dialog
      id={id}
      onClose={handleClose}
      open={open}
      className={classes.root}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableBackdropClick={disableBackdropClick}
    >
      <div className={classes.titleWrapper}>
        <Typography className={classes.title} variant="body1">
          {modalTitle}
        </Typography>
        {selectedItem && (
          <Typography className={classes.symbol} variant="body1">
            {'>'}
          </Typography>
        )}
        <Typography
          className={classes.selectedItem}
          variant="body1"
          color="primary"
        >
          {selectedItem}
        </Typography>
        <IconButton
          id="modal-close-button"
          size="small"
          onClick={handleClose}
          className={classes.closeButton}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <Typography
        className={classes.description}
        variant="body1"
        color="secondary"
      >
        {modalDescription}
      </Typography>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      {actionButtons && (
        <DialogActions className={classes.dialogActions}>
          {actionButtons}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(BasicModal);
