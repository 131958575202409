/* eslint-disable import/prefer-default-export */
import api from '../../../app/axios';

export const meetingReportsApi = (data: any) => {
  return api.post('reports/meetings', data);
};

export const meetingHostListApi = () => {
  return api.post('reports/hosts');
};
