import React, { memo } from 'react';
import moment from 'moment';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    userCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface IMeetingInfoModalProps extends WithStyles<typeof styles> {
  open: boolean;
  meeting: any;
  handleClose: () => void;
}

const MeetingInfoModal = ({
  classes,
  open,
  meeting,
  handleClose,
}: IMeetingInfoModalProps) => {
  return (
    <ActionModal
      modalTitle={meeting?.name}
      open={open}
      handleClose={handleClose}
      actinButtonLabel="Done"
      actinButtonOnClick={handleClose}
      isSingleButton
    >
      <Grid container className={classes.userCard}>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="secondary">
            Room :&nbsp;{meeting?.location}
          </Typography>
          <Typography variant="body1" color="secondary">
            Host :&nbsp;{meeting?.host?.profile?.name} | &nbsp;
            {meeting?.host?.email}
          </Typography>
          <Typography variant="body1" color="secondary">
            Time : {moment(meeting?.beginTime).format('hh:mm A')}&nbsp;&nbsp;
            {meeting?.endTime && moment(meeting?.endTime).format('hh:mm A')}
          </Typography>
          <Typography variant="body1" color="secondary">
            Participants : &nbsp;{meeting?.participantCount}
          </Typography>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(MeetingInfoModal));
