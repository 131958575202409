import api from '../../app/axios';

export const scheduleMeetingApi = (data: any) => {
  return api.post('meetings/createscheduled', data);
};

export const instantMeetingApi = (data: any) => {
  return api.post('meetings/createinstant', data);
};

export const editMeetingApi = (data: any) => {
  return api.post(`meetings/${data.id}`, data);
};
