import React, { useState, useEffect, memo } from 'react';
import { Theme } from '@material-ui/core';
import moment from 'moment';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography } from '../Typography';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    time: {
      color: theme.palette.primary.main,
      // fontWeight: 'bold',
      [theme.breakpoints.down('lg')]: {
        fontSize: '2rem',
      },
    },
    date: {
      color: theme.palette.primary.main,
      marginLeft: '2vw',
    },
  });
};

export interface IClock extends WithStyles<typeof styles> {
  id: string;
  dateOnly?: boolean;
  timeOnly?: boolean;
  customStyles?: any;
}

export const Clock: React.FC<IClock> = ({
  classes,
  id,
  dateOnly = false,
  timeOnly = false,
  customStyles,
}: IClock) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 30 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={clsx(classes.root, customStyles)} id={id}>
      {!dateOnly && (
        <Typography variant="h3" component="h3" className={classes.time}>
          {moment(currentTime).format('hh:mm A')}
        </Typography>
      )}
      {!timeOnly && (
        <Typography variant="body1" component="p" className={classes.date}>
          {moment(currentTime).format('dddd, Do MMMM YYYY')}
        </Typography>
      )}
    </div>
  );
};

export default memo(withStyles(styles)(Clock));
