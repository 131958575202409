/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import ScheduleMeetingModalView from './scheduleMeetingModal.view';
import { ShareMeetingModal } from '../../meetings/components';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
  selectSchesuleMeeting,
  actions,
  scheduleMeetingAsync,
  editMeetingAsync,
} from '../scheduleMeeting.slice';
import { getGroupsAsync, selectGroups } from '../../group/groups.slice';

export interface IScheduleMeetingModalContainer {}

const ScheduleMeetingModalContainer: React.FC<IScheduleMeetingModalContainer> = ({}: IScheduleMeetingModalContainer) => {
  const scheduleMeeting = useAppSelector(selectSchesuleMeeting);
  const groups = useAppSelector(selectGroups);
  const dispatch = useAppDispatch();

  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);

  useEffect(() => {
    if (
      scheduleMeeting.success &&
      scheduleMeeting.isScheduleModalOpen &&
      !isShareMeetingOpen
    ) {
      _scheduleMeetinOnClose();
      setIsShareMeetingOpen(true);
    }
  }, [scheduleMeeting.success]);

  useEffect(() => {
    if (scheduleMeeting.isScheduleModalOpen) {
      dispatch(getGroupsAsync(null));
    }
  }, [scheduleMeeting.isScheduleModalOpen]);

  const _scheduleMeetinOnClose = () => {
    if (scheduleMeeting.isEditMeeting)
      dispatch(actions.closeEditMeetingModal());
    else dispatch(actions.closeScheduleModal());
  };

  const _saveMeetingBtnClick = (data: any) => {
    if (scheduleMeeting.isEditMeeting) dispatch(editMeetingAsync(data));
    else dispatch(scheduleMeetingAsync(data));
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
    dispatch(actions.resetScheduleMeeting());
  };

  return (
    <>
      {scheduleMeeting.isScheduleModalOpen && (
        <ScheduleMeetingModalView
          open={scheduleMeeting.isScheduleModalOpen}
          groups={groups.list}
          handleClose={_scheduleMeetinOnClose}
          saveMeetingButtonClick={_saveMeetingBtnClick}
          isEditMeeting={scheduleMeeting.isEditMeeting}
          meeting={scheduleMeeting.data}
        />
      )}
      {isShareMeetingOpen && (
        <ShareMeetingModal
          open={isShareMeetingOpen}
          handleClose={_handleCloseShareMeetingModal}
          meeting={scheduleMeeting?.data}
        />
      )}
    </>
  );
};

export default memo(ScheduleMeetingModalContainer);
