/* eslint-disable import/prefer-default-export */

export const rolePermissions = {
  'super-admin': {
    editProfileSettings: true,
    editGeneralSettings: true,
    editPrivacyPolicySettings: true,
    editTermsSettings: true,
    editFAQSettings: true,
    editContactInfoSettings: true,
    editScreenAppSettings: true,
    resetUserPassword: true,
    screenAppIntegration: true,
    viewReports: true,
    viewAdmins: true,
    viewCustomers: true,
    viewMembers: true,
    promoteCustomers: true,
    viewGroups: true,
    viewDevices: true,
    scheduleMeeting: true,
  },
  admin: {
    editProfileSettings: true,
    editGeneralSettings: false,
    editPrivacyPolicySettings: false,
    editTermsSettings: false,
    editFAQSettings: false,
    editContactInfoSettings: false,
    editScreenAppSettings: false,
    resetUserPassword: false,
    screenAppIntegration: false,
    viewReports: true,
    viewAdmins: true,
    viewCustomers: true,
    viewMembers: true,
    promoteCustomers: true,
    viewGroups: true,
    viewDevices: true,
    scheduleMeeting: true,
  },
  subadmin: {
    editProfileSettings: true,
    editGeneralSettings: false,
    editPrivacyPolicySettings: false,
    editTermsSettings: false,
    editFAQSettings: false,
    editContactInfoSettings: false,
    editScreenAppSettings: false,
    resetUserPassword: false,
    screenAppIntegration: false,
    viewReports: true,
    viewAdmins: false,
    viewCustomers: true,
    viewMembers: true,
    promoteCustomers: false,
    viewGroups: true,
    viewDevices: true,
    scheduleMeeting: true,
  },
  customer: {
    editProfileSettings: true,
    editGeneralSettings: false,
    editPrivacyPolicySettings: false,
    editTermsSettings: false,
    editFAQSettings: false,
    editContactInfoSettings: false,
    editScreenAppSettings: false,
    resetUserPassword: false,
    screenAppIntegration: false,
    viewReports: true,
    viewAdmins: false,
    viewCustomers: false,
    viewMembers: true,
    promoteCustomers: false,
    viewGroups: true,
    viewDevices: true,
    scheduleMeeting: true,
  },
  member: {
    editProfileSettings: true,
    editGeneralSettings: false,
    editPrivacyPolicySettings: false,
    editTermsSettings: false,
    editFAQSettings: false,
    editContactInfoSettings: false,
    editScreenAppSettings: false,
    resetUserPassword: false,
    screenAppIntegration: false,
    viewReports: false,
    viewAdmins: false,
    viewCustomers: false,
    viewMembers: false,
    promoteCustomers: false,
    viewGroups: false,
    viewDevices: true,
    scheduleMeeting: false,
  },
};
