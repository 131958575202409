import React, { memo, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Typography } from '../../../components/Typography';
import { TextField } from '../../../components/TextField';
import { validateEmail } from '../../../helper/validation';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    wrapper: {
      marginBottom: theme.spacing(3),
    },
    description: {
      margin: theme.spacing(1, 0),
    },
  });
};
interface IContactUsSettingsView extends WithStyles<typeof styles> {
  settings: any;
  handleUpdateSettings: (data: any) => void;
}

const ContactUsSettingsView: React.FC<IContactUsSettingsView> = ({
  classes,
  settings,
  handleUpdateSettings,
}: IContactUsSettingsView) => {
  const [supportMail, setSupportMail] = useState<any>({
    value: '',
    error: false,
  });
  const [contactNumber, setContactNumber] = useState<any>({
    value: '',
    error: false,
  });
  const [address, setAddress] = useState<any>({
    value: '',
    error: false,
  });
  const [googleMapAPIKey, setGoogleMapAPIKey] = useState<any>({
    value: '',
    error: false,
  });

  // Social media links
  const [facebookUrl, setFacebookUrl] = useState<any>({
    value: '',
    error: false,
  });
  const [linkedinUrl, setLinkedinUrl] = useState<any>({
    value: '',
    error: false,
  });
  const [twitterUrl, setTwitterUrl] = useState<any>({
    value: '',
    error: false,
  });
  const [otherUrl, setOtherUrl] = useState<any>({
    value: '',
    error: false,
  });

  useEffect(() => {
    if (settings?.data?.contactInfo) {
      const info = settings.data.contactInfo;
      setSupportMail({ value: info.supportMail, error: false });
      setContactNumber({ value: info.contactNumber, error: false });
      setAddress({ value: info.address, error: false });
      setGoogleMapAPIKey({ value: info.googleMapAPIKey, error: false });
      setFacebookUrl({ value: info.faceBook, error: false });
      setLinkedinUrl({ value: info.linkedIn, error: false });
      setTwitterUrl({ value: info.twitter, error: false });
      setOtherUrl({ value: info.other, error: false });
    }
  }, [settings.data]);

  const _supportMailOnChange = (e: any) => {
    const error = validateEmail(e.target.value);
    setSupportMail({ value: e.target.value, error });
  };
  const _contactNumberOnChange = (e: any) => {
    setContactNumber({ value: e.target.value, error: false });
  };
  const _addressOnChange = (e: any) => {
    setAddress({ value: e.target.value, error: false });
  };
  const _googleMapAPIKeyOnChange = (e: any) => {
    setGoogleMapAPIKey({ value: e.target.value, error: false });
  };
  const _facebookUrlOnChange = (e: any) => {
    setFacebookUrl({ value: e.target.value, error: false });
  };
  const _linkedinUrlOnChange = (e: any) => {
    setLinkedinUrl({ value: e.target.value, error: false });
  };
  const _twitterUrlOnChange = (e: any) => {
    setTwitterUrl({ value: e.target.value, error: false });
  };
  const _otherUrlOnChange = (e: any) => {
    setOtherUrl({ value: e.target.value, error: false });
  };

  const _handleSaveButtonClick = () => {
    const data = {
      setting: {
        supportMail: supportMail.value,
        contactNumber: contactNumber.value,
        address: address.value,
        googleMapAPIKey: googleMapAPIKey.value,
        faceBook: facebookUrl.value,
        linkedIn: linkedinUrl.value,
        twitter: twitterUrl.value,
        other: otherUrl.value,
      },
      category: 'contact',
    };
    handleUpdateSettings(data);
  };

  return (
    <div>
      <Card customStyles={classes.root}>
        <div className={classes.wrapper}>
          <Typography component="h3" variant="h6">
            Contact Info
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            The Field Labels Marked With * Are Required Input Fields.
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6} lg={3}>
              <TextField
                label="Support Mail"
                type="email"
                value={supportMail.value}
                onChange={_supportMailOnChange}
                error={Boolean(supportMail.error)}
                helperText={supportMail.error && 'Please insert a valid email'}
              />
            </Grid>
            <Grid item sm={6} lg={3}>
              <TextField
                label="Contact Number"
                type="number"
                value={contactNumber.value}
                onChange={_contactNumberOnChange}
              />
            </Grid>
            <Grid item sm={6} lg={3}>
              <TextField
                label="Address"
                value={address.value}
                onChange={_addressOnChange}
              />
            </Grid>
            <Grid item sm={6} lg={3}>
              <TextField
                label="Google Map API Key"
                value={googleMapAPIKey.value}
                onChange={_googleMapAPIKeyOnChange}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.wrapper}>
          <Typography component="h3" variant="h6">
            Social Media Links
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            Paste Your Social Media Profile Link With Appropriate Textfields
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6} lg={3}>
              <TextField
                label="Facebook"
                placeholder="url"
                value={facebookUrl.value}
                onChange={_facebookUrlOnChange}
              />
            </Grid>
            <Grid item sm={6} lg={3}>
              <TextField
                label="LinkedIn"
                placeholder="url"
                value={linkedinUrl.value}
                onChange={_linkedinUrlOnChange}
              />
            </Grid>
            <Grid item sm={6} lg={3}>
              <TextField
                label="Twitter"
                placeholder="url"
                value={twitterUrl.value}
                onChange={_twitterUrlOnChange}
              />
            </Grid>
            <Grid item sm={6} lg={3}>
              <TextField
                label="Other"
                placeholder="url"
                value={otherUrl.value}
                onChange={_otherUrlOnChange}
              />
            </Grid>
          </Grid>
        </div>
        <Button
          // customStyles={classes.button}
          id="update-profile-save-button"
          variant="contained"
          label="Save"
          onClick={_handleSaveButtonClick}
        />
      </Card>
    </div>
  );
};

export default memo(withStyles(styles)(ContactUsSettingsView));
