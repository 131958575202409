import React, { memo, useState } from 'react';
import { Theme } from '@material-ui/core';
// import clsx from 'clsx';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { Button } from '../../../components/Button';
import { generateRoomWithoutSeparator } from '../../../helper/random';
import { isLowercaseAlphanumeric } from '../../../helper/validation';
import { replaceRoomNameWithTitle } from '../../../app/config';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IInstantMeetingModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  startMeetingButtonClick: (data: any) => void;
}

const InstantMeetingModal = ({
  classes,
  open,
  handleClose,
  startMeetingButtonClick,
}: IInstantMeetingModalProps) => {
  const [meetingName, setMeetingName] = useState({ value: '', error: '' });
  const [location, setLocation] = useState({ value: '', error: '' });

  const _handleNameChange = (e: { target: { value: any } }) => {
    if (replaceRoomNameWithTitle && isLowercaseAlphanumeric(e.target.value)) {
      setMeetingName({ value: e.target.value, error: '' });
      setLocation({ value: e.target.value, error: '' });
    } else if (!replaceRoomNameWithTitle) {
      setMeetingName({ value: e.target.value, error: '' });
    }
  };

  const _handleLocationChange = (e: { target: { value: any } }) => {
    if (isLowercaseAlphanumeric(e.target.value))
      setLocation({ value: e.target.value, error: '' });
  };

  const _generateRoomName = () => {
    const roomName = generateRoomWithoutSeparator();
    setLocation({ value: roomName, error: '' });
  };

  const _validate = () => {
    let isValidate = true;
    if (!meetingName.value) {
      isValidate = false;
      setMeetingName({ ...meetingName, error: 'Meeting Name is Required' });
    }
    return isValidate;
  };

  const _startMeetingButtonClick = () => {
    if (_validate()) {
      const data = {
        beginTime: new Date(),
        name: meetingName.value,
        location: location.value,
      };

      startMeetingButtonClick(data);
    }
  };
  return (
    <ActionModal
      modalTitle="Enter your meeting name"
      modalDescription="Create an instant meeting and share with participants"
      id="instant-meeting-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={_startMeetingButtonClick}
      actinButtonLabel="Start Meeting"
    >
      <TextField
        placeholder="Add meeting title / topic"
        onChange={_handleNameChange}
        value={meetingName.value}
        required
        error={Boolean(meetingName.error)}
        helperText={meetingName.error}
      />
      {!replaceRoomNameWithTitle && (
        <TextField
          placeholder="Add room name"
          onChange={_handleLocationChange}
          value={location.value}
          error={Boolean(location.error)}
          helperText={location.error}
        />
      )}

      {!replaceRoomNameWithTitle && (
        <Button
          color="primary"
          id="auto generate meeting-room"
          variant="text"
          label="Or generate meeting name"
          onClick={_generateRoomName}
          // customStyles={classes.tableActionButton}
        />
      )}
    </ActionModal>
  );
};

export default memo(withStyles(styles)(InstantMeetingModal));
