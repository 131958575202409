import React, { memo, useCallback, useEffect, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  PersonAdd as PersonAddIcon,
  MoreHoriz as MoreHorizIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import { map } from 'lodash';
import { IconButton } from '@material-ui/core';
import styles from './users.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { IconButton as CustomIconButton } from '../../components/Button';
import AddUsersModal from './components/addUsersModal';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { emptyUsersViewIcon } from '../../assets/icons';
import { ChangePasswordModal, DeleteUserModal } from './components';

const tableHeadings = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'more',
    label: 'More',
    options: {
      filter: true,
      sort: false,
    },
  },
];

interface IUsersView extends WithStyles<typeof styles> {
  selectUser: any;
  usersList: any;
  handleAddUser: (data: any) => void;
  handleDeleteUser: (id: string) => void;
  handleRestUserPassword: (data: any) => void;
}

const UsersView: React.FC<IUsersView> = ({
  classes,
  selectUser,
  usersList,
  handleAddUser,
  handleDeleteUser,
  handleRestUserPassword,
}: IUsersView) => {
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const UserOptionList = [
    // {
    //   label: 'Delete this user',
    //   onClick: () => setOpenDeleteUserModal(true),
    //   icon: <DeleteOutlinedIcon fontSize="small" />,
    // },
    {
      label: 'Change Password',
      onClick: () => setOpenResetPasswordModal(true),
      icon: <VisibilityOutlinedIcon fontSize="small" />,
    },
  ];

  const handleOpenMoreOption = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedUser(data);
    setOpenMoreOption(!openMoreOption);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    setAnchorEl(null);
    setOpenMoreOption(false);
  };
  const renderTableRow = useCallback(() => {
    return map(usersList, (row) => {
      return {
        ...row,
        name: row.profile.name,
        more: (
          <IconButton
            id="more-option-in-users"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
      };
    });
  }, [usersList]);

  const renderAddUserButton = () => {
    return (
      <div>
        <CustomIconButton
          customStyles={classes.button}
          onClick={() => setOpenAddUserModal(true)}
          label="Add Admin"
          id="add-users-button"
          icon={<PersonAddIcon />}
          variant="contained"
        />
      </div>
    );
  };
  if (!usersList) {
    return (
      <NoDataLayout image={emptyUsersViewIcon} heading="Add your first user">
        {renderAddUserButton()}
        <AddUsersModal
          selectUser={selectUser}
          open={openAddUserModal}
          handleClose={() => setOpenAddUserModal(false)}
          handleAddUser={handleAddUser}
        />
      </NoDataLayout>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="users-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          Admins
        </Typography>
        <div className={classes.buttonWrapper}>{renderAddUserButton()}</div>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            customStyles={classes.tableWrapper}
            id="users-list-table"
            title={
              <div id="total-users">{`Total admins: ${usersList.length}`}</div>
            }
            columns={tableHeadings}
            data={renderTableRow()}
            pagination={false}
          />
        </Card>
      </div>
      <AddUsersModal
        selectUser={selectUser}
        open={openAddUserModal}
        handleClose={() => setOpenAddUserModal(false)}
        handleAddUser={handleAddUser}
      />
      <DeleteUserModal
        selectUser={selectUser}
        open={openDeleteUserModal}
        handleClose={() => setOpenDeleteUserModal(false)}
        selectedUser={selectedUser}
        handleDeleteUser={handleDeleteUser}
      />
      <ChangePasswordModal
        selectUser={selectUser}
        open={openResetPasswordModal}
        handleClose={() => setOpenResetPasswordModal(false)}
        selectedUser={selectedUser}
        handleRestUserPassword={handleRestUserPassword}
      />
      <Dropdown
        id="user-more-option-button"
        open={Boolean(selectedUser) && openMoreOption}
        anchorRef={anchorEl}
        handleClose={handleCloseMoreOption}
        itemList={UserOptionList}
      />
    </div>
  );
};

export default memo(withStyles(styles)(UsersView));
