import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setHeader } from '../../app/axios';
import { RootState } from '../../app/store';
import { setToken } from '../../helper/localStorage';
import { actions as appActions } from '../app/app.slice';
import { getProfileAsync } from '../auth/auth.slice';
import { usersCreatePasswordApi, userVerifyApi } from './emailRouter.api';

export interface IEmailRouteState {
  data: any;
  data2: any;
  loading: boolean;
  error: any;
}

const initialState: IEmailRouteState = {
  data: null,
  data2: null,
  loading: false,
  error: null,
};

export const updateUserVerifyAsync = createAsyncThunk(
  'users/verify',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await userVerifyApi(data);
      console.log(response);
      if (response.status === 200) {
        const token = response?.data?.data?.accessToken;
        console.log(token);
        await setHeader(token);
        await setToken(token);
        dispatch(getProfileAsync(null));
      }
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully update settings',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const changeUserPasswordAsync = createAsyncThunk(
  'users/createpassword',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await usersCreatePasswordApi(data);
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully update settings',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const emailRouterSlice = createSlice({
  name: 'emailRouter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // verify user
    builder.addCase(updateUserVerifyAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUserVerifyAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(updateUserVerifyAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // update user password
    builder.addCase(changeUserPasswordAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(changeUserPasswordAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data2 = action.payload;
    });
    builder.addCase(changeUserPasswordAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = emailRouterSlice;

export const selectEmailRouter = (state: RootState) => state.emailRouter;

export default emailRouterSlice.reducer;
