import React, { memo, useEffect } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';
import { useAppSelector } from '../../../app/hooks';
import { selectMembers } from '../members.slice';

const styles = (theme: Theme) => {
  return createStyles({
    memberCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface IDeleteMemberModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  selectedMember: any;
  handleClose?: any;
  handleDeleteMember: (id: string) => void;
  selectMember: any;
}

const DeleteMemberModal = ({
  classes,
  open,
  selectedMember,
  handleClose,
  handleDeleteMember,
  selectMember,
}: IDeleteMemberModalProps) => {
  useEffect(() => {
    handleClose();
  }, [selectMember.loading]);

  return (
    <ActionModal
      modalTitle="Do you want to delete this member ?"
      open={open}
      handleClose={handleClose}
      actinButtonLabel="Delete"
      actinButtonOnClick={() => handleDeleteMember(selectedMember?._id)}
    >
      <Grid container className={classes.memberCard}>
        <Grid item xs={1}>
          <PersonOutlineOutlinedIcon color="primary" fontSize="large" />
        </Grid>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="primary">
            <b>{selectedMember?.profile?.name || 'Not available'}</b>
          </Typography>
          <Typography variant="body2" color="secondary">
            {selectedMember?.email || 'Not available'}
          </Typography>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DeleteMemberModal));
