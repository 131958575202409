import React, { memo, useCallback, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  PersonAdd as PersonAddIcon,
  MoreHoriz as MoreHorizIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import { map } from 'lodash';
import { IconButton } from '@material-ui/core';
import styles from './customers.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { IconButton as CustomIconButton } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { emptyUsersViewIcon } from '../../assets/icons';
import {
  AddCustomerModal,
  ChangePasswordModal,
  DeleteCustomerModal,
  PromoteCustomerModal,
  DemoteSubadminModal,
} from './components';

const tableHeadings = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Email',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'contactNumber',
    label: 'Contact Number',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'role',
    label: 'Role',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'more',
    label: 'More',
    options: {
      filter: true,
      sort: false,
    },
  },
];

interface ICustomersView extends WithStyles<typeof styles> {
  selectCustomer: any;
  customersList: any;
  permissions: any;
  handleAddCustomer: (data: any) => void;
  handleDeleteCustomer: (id: string) => void;
  handleRestCustomerPassword: (data: any) => void;
  handlePromoteCustomer: (data: any) => void;
  handleDemoteSubadmin: (data: any) => void;
}

const CustomersView: React.FC<ICustomersView> = ({
  classes,
  selectCustomer,
  customersList,
  permissions,
  handleAddCustomer,
  handleDeleteCustomer,
  handleRestCustomerPassword,
  handlePromoteCustomer,
  handleDemoteSubadmin,
}: ICustomersView) => {
  const [openAddCustomerModal, setOpenAddCustomerModal] = useState(false);
  const [openDeleteCustomerModal, setOpenDeleteCustomerModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openPromoteCustomerModal, setOpenPromoteCustomerModal] = useState(
    false
  );
  const [openDemoteSubadminModal, setOpenDemoteSubadminModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const getMoreOptionList = (role: string) => {
    const optionList = [
      // {
      //   label: 'Delete this Customer',
      //   onClick: () => setOpenDeleteCustomerModal(true),
      //   icon: <DeleteOutlinedIcon fontSize="small" />,
      // },
      {
        label: 'Change Password',
        onClick: () => setOpenResetPasswordModal(true),
        icon: <VisibilityOutlinedIcon fontSize="small" />,
      },
    ];
    if (role === 'customer') {
      optionList.push({
        label: 'Promote to Sub Admin',
        onClick: () => setOpenPromoteCustomerModal(true),
        icon: <VisibilityOutlinedIcon fontSize="small" />,
      });
    } else if (role === 'subadmin') {
      optionList.push({
        label: 'Demote to Customer',
        onClick: () => setOpenDemoteSubadminModal(true),
        icon: <VisibilityOutlinedIcon fontSize="small" />,
      });
    }
    return optionList;
  };

  const [CustomerOptionList, setCustomerOptionList] = useState(
    getMoreOptionList('none')
  );

  const handleOpenMoreOption = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedCustomer(data);
    if (permissions.promoteCustomers && data.role === 'customer') {
      setCustomerOptionList(getMoreOptionList('customer'));
    } else if (permissions.promoteCustomers && data.role === 'subadmin') {
      setCustomerOptionList(getMoreOptionList('subadmin'));
    } else {
      setCustomerOptionList(getMoreOptionList('none'));
    }
    setOpenMoreOption(!openMoreOption);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    setAnchorEl(null);
    setOpenMoreOption(false);
    setCustomerOptionList([]);
  };
  const renderTableRow = useCallback(() => {
    return map(customersList, (row) => {
      return {
        ...row,
        name: row.profile.name,
        contactNumber: row.profile.contactNumber,
        status: row.isVerified ? 'active' : 'pending',
        more: (
          <IconButton
            id="more-option-in-customers"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
      };
    });
  }, [customersList]);

  const renderAddCustomerButton = () => {
    return (
      <div>
        <CustomIconButton
          customStyles={classes.button}
          onClick={() => setOpenAddCustomerModal(true)}
          label="Add Customer"
          id="add-customer-button"
          icon={<PersonAddIcon />}
          variant="contained"
        />
      </div>
    );
  };
  if (!customersList) {
    return (
      <NoDataLayout
        image={emptyUsersViewIcon}
        heading="Add your first customer"
      >
        {renderAddCustomerButton()}
        <AddCustomerModal
          selectCustomer={selectCustomer}
          open={openAddCustomerModal}
          handleClose={() => setOpenAddCustomerModal(false)}
          handleAddCustomer={handleAddCustomer}
        />
      </NoDataLayout>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="customers-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          Customers
        </Typography>
        <div className={classes.buttonWrapper}>{renderAddCustomerButton()}</div>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            customStyles={classes.tableWrapper}
            id="customers-list-table"
            title={
              <div id="total-customers">{`Total customers: ${customersList.length}`}</div>
            }
            columns={tableHeadings}
            data={renderTableRow()}
            pagination={false}
          />
        </Card>
      </div>
      <AddCustomerModal
        selectCustomer={selectCustomer}
        open={openAddCustomerModal}
        handleClose={() => setOpenAddCustomerModal(false)}
        handleAddCustomer={handleAddCustomer}
      />
      <DeleteCustomerModal
        selectCustomer={selectCustomer}
        open={openDeleteCustomerModal}
        handleClose={() => setOpenDeleteCustomerModal(false)}
        selectedCustomer={selectedCustomer}
        handleDeleteCustomer={handleDeleteCustomer}
      />
      <ChangePasswordModal
        selectCustomer={selectCustomer}
        open={openResetPasswordModal}
        handleClose={() => setOpenResetPasswordModal(false)}
        selectedCustomer={selectedCustomer}
        handleRestCustomerPassword={handleRestCustomerPassword}
      />
      <PromoteCustomerModal
        selectCustomer={selectCustomer}
        open={openPromoteCustomerModal}
        handleClose={() => setOpenPromoteCustomerModal(false)}
        selectedCustomer={selectedCustomer}
        handlePromoteCustomer={handlePromoteCustomer}
      />
      <DemoteSubadminModal
        selectCustomer={selectCustomer}
        open={openDemoteSubadminModal}
        handleClose={() => setOpenDemoteSubadminModal(false)}
        selectedCustomer={selectedCustomer}
        handleDemoteSubadmin={handleDemoteSubadmin}
      />
      <Dropdown
        id="customer-more-option-button"
        open={Boolean(selectedCustomer) && openMoreOption}
        anchorRef={anchorEl}
        handleClose={handleCloseMoreOption}
        itemList={CustomerOptionList}
      />
    </div>
  );
};

export default memo(withStyles(styles)(CustomersView));
