/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/ban-types */
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchAllMembersAsync, selectGroups } from '../groups.slice';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IEditGroupModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleEditGroup: (data: any) => void;
  selectGroup: any;
  selectedGroup: any;
}

const EditGroupModal = ({
  classes,
  open,
  handleClose,
  handleEditGroup,
  selectGroup,
  selectedGroup,
}: IEditGroupModalProps) => {
  const groups = useAppSelector(selectGroups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    handleClose();
    setName({
      error: false,
      value: '',
    });
    setDescription({
      error: false,
      value: '',
    });
    setMemberIds({
      error: false,
      value: [],
    });
  }, [selectGroup.loading]);

  useEffect(() => {
    dispatch(fetchAllMembersAsync(null));
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      setName({
        value: selectedGroup.name,
        error: !selectedGroup.name,
      });
      setDescription({
        value: selectedGroup.description,
        error: !selectedGroup.description,
      });

      const autocompleteDefaultValue = [];
      for (const member1 of selectedGroup.members) {
        for (const member2 of groups.memberList) {
          if (member1.userId._id === member2._id) {
            autocompleteDefaultValue.push(member2);
          }
        }
      }
      setAutocompleteDefaultValue(autocompleteDefaultValue);
      setMemberIds({
        value: autocompleteDefaultValue,
        error: autocompleteDefaultValue.length === 0,
      });
    }
  }, [selectedGroup]);

  const [name, setName] = useState({
    error: false,
    value: '',
  });
  const [description, setDescription] = useState({
    error: false,
    value: '',
  });

  interface ImemberIdState {
    value: any[];
    error: boolean;
  }
  const [memberIds, setMemberIds] = useState<ImemberIdState>({
    error: false,
    value: [],
  });
  const [autocompleteDefaultValue, setAutocompleteDefaultValue] = useState<
    any[]
  >([]);

  const _validate = () => {
    let isValidate = true;
    if (!name.value || name.error) {
      isValidate = false;
      setName({ ...name, error: true });
    }
    if (!description.value || description.error) {
      isValidate = false;
      setDescription({ ...description, error: true });
    }
    if (memberIds.value.length === 0 || memberIds.error) {
      isValidate = false;
      setMemberIds({ ...memberIds, error: true });
    }
    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const memberIdsArr = [];
      for (const member of memberIds.value) {
        memberIdsArr.push(member._id);
      }

      const groupData = {
        groupId: selectedGroup._id,
        name: name.value,
        description: description.value,
        members: memberIdsArr,
      };
      handleEditGroup(groupData);
    }
  };

  const nameOnChange = (e: { target: { value: string } }) => {
    setName({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const descriptionOnChange = (e: { target: { value: string } }) => {
    setDescription({
      value: e.target.value,
      error: !e.target.value,
    });
  };

  const handleChange = (
    event: ChangeEvent<{}>,
    value: any[],
    reason: AutocompleteChangeReason
  ) => {
    setMemberIds({
      value,
      error: value.length === 0,
    });
  };

  return (
    <ActionModal
      modalTitle="Edit Group"
      modalDescription="Edit Group to manage meetings"
      id="edit-group-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
    >
      <TextField
        id="edit-group-name"
        value={name.value}
        error={name.error}
        placeholder="Name"
        onChange={nameOnChange}
        required
        helperText={name.error && 'Please insert a name.'}
      />
      <TextField
        id="edit-group-description"
        value={description.value}
        error={description.error}
        placeholder="Description"
        onChange={descriptionOnChange}
        required
        helperText={description.error && 'Please insert a valid description.'}
      />
      <Autocomplete
        multiple
        limitTags={2}
        id="edit-group-members"
        options={groups.memberList}
        getOptionLabel={(option) => option.profile.name}
        defaultValue={autocompleteDefaultValue}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Members"
            placeholder="Select at least one Member"
          />
        )}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(EditGroupModal));
