/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useState } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Avatar, Grid, Button as MuiButton, Icon } from '@material-ui/core';
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  Dvr as DvrIcon,
  AccessTime as AccessTimeIcon,
  Group as GroupIcon,
  Call as CallIcon,
} from '@material-ui/icons';
import prettyMilliseconds from 'pretty-ms';
import clsx from 'clsx';
import styles from './dashboard.styles';
import { Card } from '../../components/Card';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { ScheduleMeetingButtons } from '../scheduleMeeting';
import { ShareMeetingModal } from '../meetings/components';
import { NoDataLayout } from '../../components/layout';
import { Clock } from '../../components/Clock';
import {
  emptyOverviewViewIcon,
  InstantMeetingIcon,
  ScheduleMeetingIcon,
} from '../../assets/icons';

interface IDashboardView extends WithStyles<typeof styles> {
  recentMeetings: Array<any>;
  todayMeetings: Array<any>;
  overview: any;
  user: any;
  permissions: any;
  scheduleMeetingOnClick: () => void;
  instantMeetingOnClick: () => void;
  joinToMeetionButtonClick: (data: any) => void;
  openEditMeetionModal: (data: any) => void;
}

const DashboardView: React.FC<IDashboardView> = ({
  classes,
  recentMeetings,
  todayMeetings,
  overview,
  user,
  permissions,
  scheduleMeetingOnClick,
  instantMeetingOnClick,
  joinToMeetionButtonClick,
  openEditMeetionModal,
}: IDashboardView) => {
  const [isShareMeetingOpen, setIsShareMeetingOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  const _handleOpenShareMeetingModal = (data: any) => {
    setSelectedMeeting(data);
    setIsShareMeetingOpen(true);
  };

  const _handleCloseShareMeetingModal = () => {
    setIsShareMeetingOpen(false);
  };

  const _recentMeetionActionButtonClick = (isExpired: boolean, data: any) => {
    if (isExpired) openEditMeetionModal(data);
    else joinToMeetionButtonClick(data);
  };

  const recentMtnTableColumns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'host',
      label: 'Host  ',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => {
          return <div>{value?.email}</div>;
        },
      },
    },
    {
      name: 'beginTime',
      label: 'Time',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any) => {
          return <div>{moment(value).fromNow()}</div>;
        },
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const recentMtnRowEvent = useCallback(() => {
    return map(recentMeetings, (row) => {
      const isExpired = new Date() > new Date(row?.endTime);
      return {
        ...row,
        action: (
          <Button
            color={isExpired ? 'secondary' : 'primary'}
            id="overview-meeting-table-action"
            variant="text"
            label={
              !isExpired
                ? 'Rejoin'
                : row.type === 'instant' || user?.role === 'member'
                ? ''
                : 'Reschedule'
            }
            onClick={() => _recentMeetionActionButtonClick(isExpired, row)}
            customStyles={classes.rejoinButton}
          />
        ),
      };
    });
  }, [recentMeetings]);

  const renderTodayMeetings = useCallback(() => {
    return todayMeetings?.map((meeting) => {
      return (
        <Card customStyles={classes.blueCard}>
          <Grid container>
            <Grid item xs={7}>
              <Typography variant="body1" component="p">
                {meeting?.name}
              </Typography>
              <div className={classes.blueCardMeetingDetails}>
                <div className={classes.blueCardMeetingStatsItem}>
                  <AccessTimeIcon
                    className={classes.blueCardMeetingStatsIcon}
                  />
                  <Typography variant="body2" component="p">
                    {moment(meeting?.beginTime).format('hh:mm A')}
                    {meeting?.endTime &&
                      ` - ${moment(meeting?.endTime).format('hh:mm A')}`}
                  </Typography>
                </div>
                <div className={classes.blueCardMeetingStatsItem}>
                  <DvrIcon className={classes.blueCardMeetingStatsIcon} />
                  <Typography variant="body2" component="p">
                    Room : {meeting?.location}
                  </Typography>
                </div>
                <div className={classes.blueCardMeetingStatsItem}>
                  <AccountCircleOutlinedIcon
                    className={classes.blueCardMeetingStatsIcon}
                  />
                  <Typography variant="body2" component="p">
                    Host : {meeting?.host?.email}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={5} className={classes.blueCardRighWrapper}>
              <div className={classes.blueCardButtonWrapper}>
                <Button
                  id="blue-card-launch-button"
                  label="Launch"
                  variant="contained"
                  customStyles={classes.blueCardButton}
                  onClick={() => joinToMeetionButtonClick(meeting)}
                />
                <Button
                  id="blue-card-share-button"
                  label="Share invitation"
                  variant="contained"
                  customStyles={classes.blueCardButton}
                  onClick={() => _handleOpenShareMeetingModal(meeting)}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
      );
    });
  }, [todayMeetings]);

  if (recentMeetings.length === 0 && todayMeetings.length === 0) {
    return (
      <NoDataLayout
        image={emptyOverviewViewIcon}
        heading="Virtual meetings for everyone"
        description="Best of all, your personal invitation link doesn’t expire and can be used anytime."
      >
        <ScheduleMeetingButtons />
      </NoDataLayout>
    );
  }
  const filterFirstName = (fullName: string) => {
    const firstName = fullName?.split(' ')[0];
    return firstName;
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.topWrapper}>
        <Grid item xs={12} sm={7}>
          <div className={classes.leftWrapper}>
            <Card customStyles={classes.profileCard}>
              <Grid container className={classes.fullHeight}>
                <Grid item xs={12} sm={4} className={classes.profilePicWrapper}>
                  <Avatar
                    className={classes.avatar}
                    alt={user?.profile?.name || 'User'}
                    src={
                      user?.profile?.picture || '/static/images/avatar/1.jpg'
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  className={classes.profileDetailsWrapper}
                  id="dashboard-user-details"
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.profileName}
                  >
                    Hello {filterFirstName(user?.profile?.name)}!
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h5"
                    className={classes.profileTodayMeeting}
                  >
                    You have {todayMeetings?.length} meetings today
                  </Typography>
                  <div className={classes.profileStats}>
                    <div
                      className={clsx(
                        classes.profileStatsList,
                        classes.profileStatmargin
                      )}
                    >
                      <CallIcon />
                      <Typography variant="body1" component="p">
                        Total call hrs :{' '}
                        {prettyMilliseconds(overview?.totalTime || 0, {
                          verbose: true,
                        })}
                      </Typography>
                    </div>
                    <div className={classes.profileStatsList}>
                      <GroupIcon />
                      <Typography variant="body1" component="p">
                        {overview?.totalParticipants || 0} users in calls
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.fullHeight}>
          <Card customStyles={classes.rightWrapper}>
            <Clock id="dashboard-clock" customStyles={classes.clockWrapper} />
            <div className={classes.createMeetingWrapper}>
              <MuiButton
                id="instant-meeting-button-dashboard"
                disableTouchRipple
                onClick={instantMeetingOnClick}
              >
                <Icon className={classes.createMeetingIcons}>
                  <img src={InstantMeetingIcon} alt="Instant meeting" />
                </Icon>
                <Typography variant="body1" component="p">
                  Start Call
                </Typography>
              </MuiButton>
              {user?.role !== 'member' ? (
                <MuiButton
                  disableTouchRipple
                  onClick={scheduleMeetingOnClick}
                  id="schedule-meeting-button-dashboard"
                >
                  <Icon className={classes.createMeetingIcons}>
                    <img src={ScheduleMeetingIcon} alt="Schedule a meeting" />
                  </Icon>

                  <Typography variant="body1" component="p">
                    Schedule
                  </Typography>
                </MuiButton>
              ) : (
                ''
              )}
              <MuiButton
                id="help-button-dashboard"
                disableTouchRipple
                // onClick={instantMeetingOnClick}
              >
                <Icon className={classes.createMeetingIcons}>
                  <HelpOutlineIcon fontSize="large" />
                </Icon>
                <Typography variant="body1" component="p">
                  Help
                </Typography>
              </MuiButton>
            </div>
          </Card>
        </Grid>
        {isShareMeetingOpen && (
          <ShareMeetingModal
            open={isShareMeetingOpen}
            handleClose={_handleCloseShareMeetingModal}
            meeting={selectedMeeting}
          />
        )}
      </Grid>
      <div style={{ flexGrow: 1, height: '50%' }}>
        <Grid container spacing={3} className={classes.fullHeight}>
          <Grid item xs={12} sm={7} className={classes.fullHeight}>
            <div className={classes.leftBottom}>
              {todayMeetings.length > 0 ? (
                renderTodayMeetings()
              ) : (
                <NoDataLayout
                  image={emptyOverviewViewIcon}
                  headingTypeTwo="You have no upcoming meetings today"
                  customStyles={classes.noUpComming}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div className={classes.leftBottomRighWrapper}>
              <Card
                customStyles={classes.recentMeetingsWrapper}
                id="recent-meetings"
              >
                <Table
                  id="recent-meetings-table"
                  title="Recent Meetings"
                  customStyles={classes.recentMeetingsTable}
                  columns={recentMtnTableColumns}
                  data={recentMtnRowEvent()}
                  pagination={false}
                />
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(DashboardView));
