import React, { memo } from 'react';
import { Card, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1.5),
      borderRadius: '6px',
      height: `calc(100% - ${theme.spacing(3)}px)`,
      opacity: 1,
      boxShadow: 'none',
    },
  });
};

export interface ICardComponentProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  customStyles?: any;
  id?: string;
}

const CardComponent = ({
  classes,
  id,
  children,
  customStyles,
}: ICardComponentProps) => {
  return (
    <Card
      className={clsx(classes.container, customStyles && customStyles)}
      id={id}
    >
      {children}
    </Card>
  );
};

export default memo(withStyles(styles)(CardComponent));
