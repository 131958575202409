/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { useAppSelector } from '../../app/hooks';
import { selectSettings } from '../settings/settings.slice';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      flexGrow: 1,
      overflow: 'auto',
      marginTop: theme.spacing(2),
    },
  });
};

type IContactView = WithStyles<typeof styles>;

const ContactView: React.FC<IContactView> = ({ classes }) => {
  const settings = useAppSelector(selectSettings);
  const [contact, setContact] = useState<any>([]);
  useEffect(() => {
    if (settings?.data?.contactInfo) {
      setContact([
        {
          label: 'Email',
          value: settings?.data?.contactInfo?.supportMail,
        },
        {
          label: 'Contact Number',
          value: settings?.data?.contactInfo?.contactNumber,
        },
        {
          label: 'Adress',
          value: settings?.data?.contactInfo?.address,
        },
        {
          label: 'Facebook',
          value: settings?.data?.contactInfo?.faceBook,
        },
        {
          label: 'LinkedIn',
          value: settings?.data?.contactInfo?.linkedIn,
        },
        {
          label: 'Twitter',
          value: settings?.data?.contactInfo?.twitter,
        },
      ]);
    }
  }, [settings?.data?.contactInfo]);
  return (
    <div className={classes.root}>
      <Card customStyles={classes.card}>
        <Typography variant="h4" component="h2" color="textPrimary">
          Contact Us
        </Typography>
        <br />
        <table>
          {contact &&
            contact.map((data: any) => {
              return (
                <tr>
                  <td>
                    <Typography
                      variant="h5"
                      component="body1"
                      color="textPrimary"
                    >
                      {data.label}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      variant="h5"
                      component="body1"
                      color="textPrimary"
                    >
                      : {data.value}
                    </Typography>
                  </td>
                </tr>
              );
            })}
        </table>
        <div />
      </Card>
    </div>
  );
};

export default memo(withStyles(styles)(ContactView));
