/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  assignDeviceAsync,
  unassignDevicesAsync,
  fetchAllDevicesAsync,
  selectDevices,
} from './devices.slice';
import { selectAuth } from '../auth/auth.slice';
import DevicesView from './devices.view';

export interface IDevicesContainer {}

const DevicesContainer: React.FC<IDevicesContainer> = ({}: IDevicesContainer) => {
  const devices = useAppSelector(selectDevices);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllDevicesAsync(null));
  }, []);

  const handleAddDevice = (data: any) => {
    dispatch(assignDeviceAsync(data));
  };

  const handleDeleteDevice = (data: any) => {
    dispatch(unassignDevicesAsync(data));
  };

  return (
    <DevicesView
      selectDevice={devices}
      devicesList={devices.list}
      permissions={auth.permissions}
      handleAddDevice={handleAddDevice}
      handleDeleteDevice={handleDeleteDevice}
    />
  );
};

export default memo(DevicesContainer);
