import { combineReducers } from 'redux';

import { authReducer } from '../features/auth';
import { meetingsReducer } from '../features/meetings';
import { scheduleMeetingsReducer } from '../features/scheduleMeeting';
import { usersReducer } from '../features/users';
import { membersReducer } from '../features/members';
import { customersReducer } from '../features/customers';
import { appReducer } from '../features/app';
import { meetingRoomReducer } from '../features/meetingRoom';
import { settingsReducer } from '../features/settings';
import { meetingReportsReducer } from '../features/reports/meetingReport';
import { emailRouterReducer } from '../features/emailRouter';
import { groupsReducer } from '../features/group';
import { devicesReducer } from '../features/devices';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  settings: settingsReducer,
  meetings: meetingsReducer,
  scheduleMeeting: scheduleMeetingsReducer,
  meetingRoom: meetingRoomReducer,
  users: usersReducer,
  members: membersReducer,
  customers: customersReducer,
  reports: meetingReportsReducer,
  emailRouter: emailRouterReducer,
  groups: groupsReducer,
  devices: devicesReducer,
});

export default rootReducer;
