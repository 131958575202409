/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addCustomerAsync,
  deleteCustomersAsync,
  demoteSubadminAsync,
  fetchAllCustomersAsync,
  promoteCustomerAsync,
  resetCustomersPasswordAsync,
  selectCustomers,
} from './customers.slice';
import { selectAuth } from '../auth/auth.slice';
import CustomersView from './customers.view';

export interface ICustomersContainer {}

const CustomersContainer: React.FC<ICustomersContainer> = ({}: ICustomersContainer) => {
  const customers = useAppSelector(selectCustomers);
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllCustomersAsync(null));
  }, []);

  const handleAddCustomer = (data: any) => {
    const { email, name, contactNumber } = data;
    dispatch(addCustomerAsync({ email, name, contactNumber }));
  };

  const handleDeleteCustomer = (id: string) => {
    dispatch(deleteCustomersAsync(id));
  };

  const handlePromoteCustomer = (id: string) => {
    dispatch(promoteCustomerAsync(id));
  };

  const handleDemoteSubadmin = (id: string) => {
    dispatch(demoteSubadminAsync(id));
  };

  const handleRestCustomerPassword = (data: any) => {
    navigator.clipboard.writeText(data.password);
    dispatch(resetCustomersPasswordAsync(data));
  };

  return (
    <CustomersView
      selectCustomer={customers}
      customersList={customers.list}
      permissions={auth.permissions}
      handleAddCustomer={handleAddCustomer}
      handleDeleteCustomer={handleDeleteCustomer}
      handleRestCustomerPassword={handleRestCustomerPassword}
      handlePromoteCustomer={handlePromoteCustomer}
      handleDemoteSubadmin={handleDemoteSubadmin}
    />
  );
};

export default memo(CustomersContainer);
