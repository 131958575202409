import React, { memo, useEffect } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    deviceCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface IDeleteDeviceModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  selectedDevice: any;
  handleClose?: any;
  handleDeleteDevice: any;
  selectDevice: any;
}

const DeleteDeviceModal = ({
  classes,
  open,
  selectedDevice,
  handleClose,
  handleDeleteDevice,
  selectDevice,
}: IDeleteDeviceModalProps) => {
  useEffect(() => {
    handleClose();
  }, [selectDevice.loading]);

  return (
    <ActionModal
      modalTitle="Do you want to delete this device?"
      open={open}
      handleClose={handleClose}
      actinButtonLabel="Delete"
      actinButtonOnClick={() =>
        handleDeleteDevice({ deviceId: selectedDevice?.id })
      }
    >
      <Grid container className={classes.deviceCard}>
        <Grid item xs={1}>
          <PersonOutlineOutlinedIcon color="primary" fontSize="large" />
        </Grid>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="primary">
            <b>{selectedDevice?.deviceName || 'Not available'}</b>
          </Typography>
          <Typography variant="body2" color="secondary">
            {selectedDevice?.id || 'Not available'}
          </Typography>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DeleteDeviceModal));
