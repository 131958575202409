/* eslint-disable no-unused-vars */
import React from 'react';
import { Switch, Theme } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

const CustomizedSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 33,
      height: 21,
      padding: 0,
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.dark,
        },
      },
    },
    thumb: {
      width: 17,
      height: 17,
      boxShadow: 'none',
    },
    track: {
      borderRadius: 1000,
      opacity: 1,
      height: 'auto',
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.grey[300],
    },
    checked: {},
  })
)(Switch);

export interface IBasicSwitchProps {
  checked?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const BasicSwitch: React.FC<IBasicSwitchProps> = ({
  checked = true,
  onChange,
}: IBasicSwitchProps) => {
  return (
    <div>
      <CustomizedSwitch checked={checked} onChange={onChange} />
    </div>
  );
};

export default BasicSwitch;
