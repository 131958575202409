import React, { memo, useState, useEffect } from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { TextField, PasswordTextField } from '../../../components/TextField';
import { validatePassword } from '../../../helper/validation';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IChangePasswordModalProps extends WithStyles<typeof styles> {
  open: boolean;
  handleClose: () => void;
  handleRestMemberPassword: (data: any) => void;
  selectMember: any;
  selectedMember: any;
}

const ChangePasswordModal = ({
  classes,
  open,
  handleClose,
  handleRestMemberPassword,
  selectMember,
  selectedMember,
}: IChangePasswordModalProps) => {
  const [password, setPassword] = useState({ error: false, value: '' });

  useEffect(() => {
    handleClose();
  }, [selectMember.loading]);

  const passwordOnChange = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setPassword({ value: e.target.value, error });
  };

  const _validate = () => {
    let isValidate = true;

    if (!password.value || password.error) {
      isValidate = false;
      setPassword({ ...password, error: true });
    }

    return isValidate;
  };
  const onClickActionButton = () => {
    if (_validate()) {
      const memberData = {
        _id: selectedMember?._id,
        password: password.value,
      };
      handleRestMemberPassword(memberData);
    }
  };
  return (
    <ActionModal
      modalTitle="Change member password"
      selectedItem={selectedMember?.profile?.name}
      modalDescription="Copy new password and send it to the member"
      open={open}
      handleClose={handleClose}
      actinButtonLabel="Copy Password and Save"
      actinButtonOnClick={onClickActionButton}
    >
      <TextField
        id="change-password-email"
        value={selectedMember?.email}
        disabled
      />
      <PasswordTextField
        id="change-password-input"
        placeholder="New Password"
        onChange={passwordOnChange}
        required
        error={password.error}
        helperText={password.error && 'Please insert a valid Password.'}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(ChangePasswordModal));
