import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  assignDeviceApi,
  unassignDeviceApi,
  fetchAllDevicesApi,
} from './devices.api';
import { actions as appActions } from '../app/app.slice';

export interface IDevicesState {
  list: Array<any>;
  assignDevice: any;
  unassignDevice: any;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IDevicesState = {
  list: [],
  assignDevice: null,
  unassignDevice: null,
  loading: false,
  success: false,
  error: null,
};

export const fetchAllDevicesAsync = createAsyncThunk(
  'devices/fetchAll',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllDevicesApi();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const assignDeviceAsync = createAsyncThunk(
  'devices/assignDevice',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await assignDeviceApi(data);
      dispatch(fetchAllDevicesAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully create device',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const unassignDevicesAsync = createAsyncThunk(
  'devices/unassignDevice',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await unassignDeviceApi(data);
      dispatch(fetchAllDevicesAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully unassign device',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch all devices
    builder.addCase(fetchAllDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
    });
    builder.addCase(fetchAllDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Assign device
    builder.addCase(assignDeviceAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(assignDeviceAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.assignDevice = action.payload;
    });
    builder.addCase(assignDeviceAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Unassign device
    builder.addCase(unassignDevicesAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(unassignDevicesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.unassignDevice = action.payload;
    });
    builder.addCase(unassignDevicesAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = devicesSlice;

export const selectDevices = (state: RootState) => state.devices;

export default devicesSlice.reducer;
