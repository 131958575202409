/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
import React, { memo, useState } from 'react';
import { Grid, withStyles, Tooltip } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import moment from 'moment';
import styles from './meetingReport.styles';
import { Card } from '../../../components/Card';
import { CollapsibleTable } from '../../../components/CollapsibleTable';
import { Typography } from '../../../components/Typography';
import { TimePicker } from '../../../components/TimePicker';
import SelectTextField from '../../../components/TextField/SelectTextField';

interface IMeetingReport extends WithStyles<typeof styles> {
  meetingsReports: Array<any>;
  hostList: Array<any>;
  fromDate: { value: string; error: string };
  toDate: { value: any; error: string };
  selectedHost: string;
  setFromDate: (e: any) => void;
  setToDate: (e: any) => void;
  setSelectedHost: (e: any) => void;
}

const tooltipText = (props: any) => {
  if (props === 'pending') {
    return 'Meeting Scheduled';
  }
  if (props === 'waiting') {
    return 'Waiting for the host/participants';
  }
  if (props === 'inprogress') {
    return 'Meeting has started';
  }
  if (props === 'expired') {
    return 'Meeting has expired, it looks like no one joined';
  }
  return 'Meeting concluded';
};

const MeetingReport: React.FC<IMeetingReport> = ({
  classes,
  meetingsReports,
  hostList,
  fromDate,
  toDate,
  selectedHost,
  setFromDate,
  setToDate,
  setSelectedHost,
}: IMeetingReport) => {
  const MeetingTableColumns = [
    {
      name: 'meetings',
      label: 'Meetings list',
      options: {
        display: false,
        customBodyRender: (value: any) => <div> {JSON.stringify(value)}</div>,
      },
    },
    {
      name: 'name',
      label: 'Meeting Title',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: `host`,
      label: 'Host',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => <div> {value?.profile?.name}</div>,
      },
    },
    {
      name: 'beginTime',
      label: 'Started Time',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => (
          <div> {moment(new Date(value)).format('YYYY-MM-DD HH:mm:ss')}</div>
        ),
      },
    },
    {
      name: 'duration',
      label: 'Duration (Min)',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => <div> {value || 0}</div>,
      },
    },
    {
      name: 'participants',
      label: 'Participants',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => <div> {value || 0}</div>,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => (
          <div>
            <Tooltip title={tooltipText(value)}>
              <div> {value} </div>
            </Tooltip>
          </div>
        ),
      },
    },
  ];
  const _hostArray = [
    {
      label: 'All Hosts',
      value: 'all',
    },
  ];
  for (let i = 0; i < hostList.length; i++) {
    _hostArray.push({
      label: hostList[i]?.profile?.name,
      value: hostList[i]?._id,
    });
  }

  const options = {};

  return (
    <div className={classes.root} id="meeting-report-view">
      <div className={classes.topWrapper}>
        <Typography
          className={classes.heading}
          variant="body1"
          component="h6"
          color="textPrimary"
        >
          <b>Reports</b> / Meetings
        </Typography>
      </div>
      <div className={classes.bottomWrapper}>
        <Grid container className={classes.tableContainer}>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Card customStyles={classes.cardLeft}>
              <div className="MuiToolbar-regular MuiToolbar-root">
                <Typography
                  variant="h6"
                  component="body1"
                  className={classes.title}
                >
                  Search
                </Typography>
              </div>
              <br />
              <SelectTextField
                selectItems={_hostArray}
                value={selectedHost}
                label="Search by host"
                onChange={(e: any) => setSelectedHost(e.target.value)}
              />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TimePicker
                    label="From"
                    type="date"
                    id="meeting-report-from-date"
                    onChange={(e: any) => setFromDate(e)}
                    value={fromDate.value}
                    error={Boolean(fromDate.error)}
                    helperText={fromDate.error}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TimePicker
                    label="To"
                    type="date"
                    id="meeting-report-to-date"
                    onChange={(e: any) => setToDate(e)}
                    value={toDate.value}
                    error={Boolean(toDate.error)}
                    helperText={toDate.error}
                  />
                </Grid>
              </Grid>
              <br />
            </Card>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.gridItem}>
            <Card>
              <CollapsibleTable
                title="Found meetings based on your search"
                id="report-meeting"
                columns={MeetingTableColumns}
                options={options}
                data={meetingsReports}
                customStyles={classes.table}
                pagination={false}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(MeetingReport));
