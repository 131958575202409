import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  addGroupApi,
  editGroupApi,
  deleteGroupApi,
  fetchAllMembersApi,
  getGroupsApi,
} from './groups.api';
import { actions as appActions } from '../app/app.slice';

export interface IGroupsState {
  list: Array<any>;
  memberList: Array<any>;
  deleteGroup: any;
  editGroup: any;
  addGroup: any;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IGroupsState = {
  list: [],
  memberList: [],
  deleteGroup: null,
  editGroup: null,
  addGroup: null,
  loading: false,
  success: false,
  error: null,
};

export const getGroupsAsync = createAsyncThunk(
  'group/read',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await getGroupsApi();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAllMembersAsync = createAsyncThunk(
  'members/fetchAll',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllMembersApi();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addGroupAsync = createAsyncThunk(
  'group/create',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await addGroupApi(data);
      dispatch(getGroupsAsync(null));
      dispatch(fetchAllMembersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully created group',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteGroupAsync = createAsyncThunk(
  'group/destroy',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteGroupApi(data);
      dispatch(getGroupsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully deleted group',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editGroupAsync = createAsyncThunk(
  'group/assign',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await editGroupApi(data);
      dispatch(getGroupsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully updated group',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch all groups
    builder.addCase(getGroupsAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getGroupsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
    });
    builder.addCase(getGroupsAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Fetch all members
    builder.addCase(fetchAllMembersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMembersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.memberList = action?.payload?.data;
    });
    builder.addCase(fetchAllMembersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add new groups
    builder.addCase(addGroupAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addGroupAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.addGroup = action.payload;
    });
    builder.addCase(addGroupAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete groups
    builder.addCase(deleteGroupAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteGroupAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteGroup = action.payload;
    });
    builder.addCase(deleteGroupAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Edit group
    builder.addCase(editGroupAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editGroupAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.editGroup = action.payload;
    });
    builder.addCase(editGroupAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = groupsSlice;

export const selectGroups = (state: RootState) => state.groups;

export default groupsSlice.reducer;
