/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import {
  changeUserPasswordAsync,
  selectEmailRouter,
  updateUserVerifyAsync,
} from './emailRouter.slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import EmailRouterView from './emailRouter.view';
import { selectSettings } from '../settings/settings.slice';

export interface IEmailRouterContainer {}

const EmailRouterContainer: React.FC<IEmailRouterContainer> = ({}: IEmailRouterContainer) => {
  const dispatch = useAppDispatch();
  const emailRouter = useAppSelector(selectEmailRouter);
  const settings = useAppSelector(selectSettings);
  const { data, data2, loading, error } = emailRouter;
  const history = useHistory();
  const { location } = history;

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (queryParams && queryParams.accountconfirmation) {
      // User clicked on reset password email link
      apiUpdateUserVerifyAsync({ token: queryParams.token });
    }
  }, []);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (loading === false) {
      if (data && data.success === true) {
        if (queryParams && queryParams.accountconfirmation) {
          // setChangePassword(true);
        }
      } else if ((data && data.success === false) || error) {
        if (queryParams && queryParams.accountconfirmation) {
          history.push('/verificationtoken_expired');
        }
      }
    }
  }, [data, loading, error, location.search]);

  useEffect(() => {
    if (loading === false) {
      if (data2 && data2.success === true) {
        history.push('/');
      }
    }
  }, [data2, loading, error, location.search]);

  const apiUpdateUserVerifyAsync = (data3: any) => {
    dispatch(updateUserVerifyAsync(data3));
  };

  const handleChangeUserPassword = (data4: any) => {
    navigator.clipboard.writeText(data4.password);
    dispatch(changeUserPasswordAsync(data4));
  };

  return (
    <EmailRouterView
      handleChangeUserPassword={handleChangeUserPassword}
      generalSettings={settings?.data?.general}
    />
  );
};

export default memo(EmailRouterContainer);
