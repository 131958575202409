import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  addCustomerApi,
  deleteCustomerApi,
  demoteSubadminApi,
  fetchAllCustomersApi,
  promoteCustomerApi,
  resetCustomerPasswordApi,
} from './customers.api';
import { actions as appActions } from '../app/app.slice';

export interface ICustomersState {
  list: Array<any>;
  deleteCustomer: any;
  resetCustomerPassword: any;
  addCustomer: any;
  promoteCustomer: any;
  demoteSubadmin: any;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: ICustomersState = {
  list: [],
  deleteCustomer: null,
  resetCustomerPassword: null,
  addCustomer: null,
  promoteCustomer: null,
  demoteSubadmin: null,
  loading: false,
  success: false,
  error: null,
};

export const fetchAllCustomersAsync = createAsyncThunk(
  'customers/fetchAll',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllCustomersApi();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addCustomerAsync = createAsyncThunk(
  'customers/addCustomer',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await addCustomerApi(data);
      dispatch(fetchAllCustomersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully create customer',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteCustomersAsync = createAsyncThunk(
  'customers/deleteCustomer',
  async (data: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteCustomerApi(data);
      dispatch(fetchAllCustomersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully delete customer',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const resetCustomersPasswordAsync = createAsyncThunk(
  'customers/resetCustomerPassword',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await resetCustomerPasswordApi(data);
      dispatch(fetchAllCustomersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully reset password',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const promoteCustomerAsync = createAsyncThunk(
  'customers/promotecustomer',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await promoteCustomerApi(data);
      dispatch(fetchAllCustomersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully promoted customer',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const demoteSubadminAsync = createAsyncThunk(
  'customers/demotesubadmin',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await demoteSubadminApi(data);
      dispatch(fetchAllCustomersAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully demoted subadmin',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch all customers
    builder.addCase(fetchAllCustomersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllCustomersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
    });
    builder.addCase(fetchAllCustomersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add new customers
    builder.addCase(addCustomerAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addCustomerAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.addCustomer = action.payload;
    });
    builder.addCase(addCustomerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete customers
    builder.addCase(deleteCustomersAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteCustomersAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteCustomer = action.payload;
    });
    builder.addCase(deleteCustomersAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Reset customer password
    builder.addCase(resetCustomersPasswordAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetCustomersPasswordAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.resetCustomerPassword = action.payload;
    });
    builder.addCase(resetCustomersPasswordAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Promote customer
    builder.addCase(promoteCustomerAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(promoteCustomerAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.promoteCustomer = action.payload;
    });
    builder.addCase(promoteCustomerAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Demote subadmin
    builder.addCase(demoteSubadminAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(demoteSubadminAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.demoteSubadmin = action.payload;
    });
    builder.addCase(demoteSubadminAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { actions } = customersSlice;

export const selectCustomers = (state: RootState) => state.customers;

export default customersSlice.reducer;
