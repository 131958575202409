import React, { memo } from 'react';
import moment from 'moment';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { VideocamOutlined } from '@material-ui/icons';

import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    userCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontWeight: 'bold',
    },
    symbol: {
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
    selectedItem: {
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
  });
};

export interface IDeleteMeetingModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  meeting: any;
  handleClose: () => void;
  removeMeeting: (id: string) => void;
}

const DeleteMeetingModal = ({
  classes,
  open,
  meeting,
  handleClose,
  removeMeeting,
}: IDeleteMeetingModalProps) => {
  const _removeMeetingButtonOnClick = () => {
    if (meeting?._id) {
      removeMeeting(meeting?._id);
      handleClose();
    }
  };

  return (
    <ActionModal
      modalTitle="Do you want to delete this Meeting ?"
      open={open}
      handleClose={handleClose}
      actinButtonLabel="Delete"
      actinButtonOnClick={_removeMeetingButtonOnClick}
    >
      <Grid container className={classes.userCard}>
        <Grid item xs={1}>
          <VideocamOutlined color="primary" fontSize="large" />
        </Grid>
        <Grid item className={classes.right}>
          <div className={classes.titleWrapper}>
            <Typography
              className={classes.title}
              variant="body1"
              color="primary"
            >
              {meeting?.name}
            </Typography>
            <Typography className={classes.symbol} variant="body1">
              <b>{'>'}</b>
            </Typography>
            <Typography
              className={classes.selectedItem}
              variant="body1"
              color="secondary"
            >
              {meeting?.location}
            </Typography>
          </div>
          <Typography variant="body1" color="secondary">
            {moment(meeting?.beginTime).format('hh:mm A')}&nbsp;&nbsp;
            {meeting?.endTime && moment(meeting?.endTime).format('hh:mm A')}
          </Typography>
          <Typography variant="body1" color="secondary">
            Host :&nbsp;{meeting?.host?.profile?.name} | &nbsp;
            {meeting?.host?.email}
          </Typography>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DeleteMeetingModal));
