import React, { memo } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import RouteWrapper from './RouteWrapper';
import { LoginView } from '../features/auth';
import { Dashboard } from '../features/dashboard';
import { Meetings } from '../features/meetings';
import { Users } from '../features/users';
import { Members } from '../features/members';
import { Customers } from '../features/customers';
import { MeetingRoom } from '../features/meetingRoom';
import { Settings } from '../features/settings';
import { MeetingReports } from '../features/reports/meetingReport';
import { ContactView, PrivacyView, TermsView } from '../features/subPages';
import { EmailRouter } from '../features/emailRouter';
import { Groups } from '../features/group';
import { Devices } from '../features/devices';
import InvitationExpiredView from '../features/invitationExpired/invitationExpired.view';

const Routes = () => {
  const routes = [
    {
      path: '/',
      exact: true,
      isPrivate: true,
      component: () => <Dashboard />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/overview',
      exact: true,
      isPrivate: true,
      component: () => <Dashboard />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/admins',
      exact: true,
      isPrivate: true,
      component: () => <Users />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/members',
      exact: true,
      isPrivate: true,
      component: () => <Members />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/customers',
      exact: true,
      isPrivate: true,
      component: () => <Customers />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/meetings',
      exact: true,
      isPrivate: true,
      component: () => <Meetings />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/room/:roomName',
      exact: false,
      isPrivate: false,
      component: () => <MeetingRoom />,
      hasNavbar: false,
      hasFooter: false,
    },
    {
      path: '/signin',
      exact: true,
      isPrivate: false,
      component: () => <LoginView />,
    },
    {
      path: '/settings',
      exact: true,
      isPrivate: true,
      component: () => <Settings />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/terms-of-use',
      exact: true,
      isPrivate: true,
      component: () => <TermsView />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/privacy-policy',
      exact: true,
      isPrivate: true,
      component: () => <PrivacyView />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/contact-us',
      exact: true,
      isPrivate: true,
      component: () => <ContactView />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/reports/meetings',
      exact: true,
      isPrivate: true,
      component: () => <MeetingReports />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/emailroute',
      exact: true,
      isPrivate: false,
      component: () => <EmailRouter />,
      hasNavbar: false,
      hasFooter: false,
    },
    {
      path: '/verificationtoken_expired',
      exact: true,
      isPrivate: false,
      component: () => <InvitationExpiredView />,
      hasNavbar: false,
      hasFooter: false,
    },
    {
      path: '/groups',
      exact: true,
      isPrivate: true,
      component: () => <Groups />,
      hasNavbar: true,
      hasFooter: true,
    },
    {
      path: '/devices',
      exact: true,
      isPrivate: true,
      component: () => <Devices />,
      hasNavbar: true,
      hasFooter: true,
    },
  ];
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route) => (
          <RouteWrapper
            key={route.path}
            path={route.path}
            exact={route.exact}
            isPrivate={route.isPrivate}
            Component={route.component}
            hasNavbar={route.hasNavbar}
            hasFooter={route.hasFooter}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default memo(Routes);
