import React, { memo, useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { isLowercaseAlphanumeric } from '../../../helper/validation';
import { TextField } from '../../../components/TextField';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

export interface IAddDeviceModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: any;
  handleAddDevice: (data: any) => void;
  selectDevice: any;
}

const AddDeviceModal = ({
  classes,
  open,
  handleClose,
  handleAddDevice,
  selectDevice,
}: IAddDeviceModalProps) => {
  const [deviceId, setDeviceId] = useState({ error: false, value: '' });

  useEffect(() => {
    handleClose();
  }, [selectDevice.loading]);

  const _validate = () => {
    let isValidate = true;
    if (!deviceId.value || deviceId.error) {
      isValidate = false;
      setDeviceId({ ...deviceId, error: true });
    }

    return isValidate;
  };

  const onClickActionButton = () => {
    if (_validate()) {
      const deviceData = {
        deviceId: deviceId.value,
      };
      handleAddDevice(deviceData);
    }
  };

  const deviceIdOnChange = (e: { target: { value: string } }) => {
    const error = !isLowercaseAlphanumeric(e.target.value);
    setDeviceId({ value: e.target.value, error });
  };

  return (
    <ActionModal
      modalTitle="Add Device"
      modalDescription="Add Device to manage meetings"
      id="add-device-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={onClickActionButton}
    >
      <TextField
        id="add-device-deviceId"
        error={deviceId.error}
        placeholder="Device ID"
        required
        onChange={deviceIdOnChange}
        helperText={deviceId.error && 'Please insert a valid deviceId address.'}
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(AddDeviceModal));
