import React, { memo, useCallback, useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import {
  PersonAdd as PersonAddIcon,
  MoreHoriz as MoreHorizIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';
import { map } from 'lodash';
import { IconButton } from '@material-ui/core';
import styles from './groups.styles';
import { Typography } from '../../components/Typography';
import { Card } from '../../components/Card';
import { Table } from '../../components/Table';
import { IconButton as CustomIconButton } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { NoDataLayout } from '../../components/layout';
import { emptyUsersViewIcon } from '../../assets/icons';
import { AddGroupModal, DeleteGroupModal, EditGroupModal } from './components';

const tableHeadings = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'membersCount',
    label: 'Members Count',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'more',
    label: 'More',
    options: {
      filter: true,
      sort: false,
    },
  },
];

interface IGroupsView extends WithStyles<typeof styles> {
  selectGroup: any;
  groupsList: any;
  permissions: any;
  handleAddGroup: (data: any) => void;
  handleDeleteGroup: (id: string) => void;
  handleEditGroup: (data: any) => void;
}

const GroupsView: React.FC<IGroupsView> = ({
  classes,
  selectGroup,
  groupsList,
  permissions,
  handleAddGroup,
  handleDeleteGroup,
  handleEditGroup,
}: IGroupsView) => {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false);
  const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
  const [openMoreOption, setOpenMoreOption] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const optionList = [
    {
      label: 'Edit',
      onClick: () => setOpenEditGroupModal(true),
      icon: <VisibilityOutlinedIcon fontSize="small" />,
    },
    {
      label: 'Delete this Group',
      onClick: () => setOpenDeleteGroupModal(true),
      icon: <DeleteOutlinedIcon fontSize="small" />,
    },
  ];

  const handleOpenMoreOption = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setSelectedGroup(data);
    setOpenMoreOption(!openMoreOption);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreOption = (event: React.MouseEvent<EventTarget>) => {
    setAnchorEl(null);
    setOpenMoreOption(false);
  };

  const renderTableRow = useCallback(() => {
    return map(groupsList, (row) => {
      return {
        ...row,
        membersCount: row.members.length,
        more: (
          <IconButton
            id="more-option-in-groups"
            size="small"
            onClick={(e) => handleOpenMoreOption(e, row)}
          >
            <MoreHorizIcon fontSize="inherit" color="primary" />
          </IconButton>
        ),
      };
    });
  }, [groupsList]);

  const renderAddGroupButton = () => {
    return (
      <div>
        <CustomIconButton
          customStyles={classes.button}
          onClick={() => setOpenAddGroupModal(true)}
          label="Add Group"
          id="add-group-button"
          icon={<PersonAddIcon />}
          variant="contained"
        />
      </div>
    );
  };
  if (!groupsList) {
    return (
      <NoDataLayout image={emptyUsersViewIcon} heading="Add your first group">
        {renderAddGroupButton()}
        <AddGroupModal
          selectGroup={selectGroup}
          open={openAddGroupModal}
          handleClose={() => setOpenAddGroupModal(false)}
          handleAddGroup={handleAddGroup}
        />
      </NoDataLayout>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper} id="groups-view">
        <Typography variant="h3" component="h2" color="textPrimary">
          Groups
        </Typography>
        <div className={classes.buttonWrapper}>{renderAddGroupButton()}</div>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.card}>
          <Table
            customStyles={classes.tableWrapper}
            id="groups-list-table"
            title={
              <div id="total-groups">{`Total groups: ${groupsList.length}`}</div>
            }
            columns={tableHeadings}
            data={renderTableRow()}
            pagination={false}
          />
        </Card>
      </div>
      <AddGroupModal
        selectGroup={selectGroup}
        open={openAddGroupModal}
        handleClose={() => setOpenAddGroupModal(false)}
        handleAddGroup={handleAddGroup}
      />
      <DeleteGroupModal
        selectGroup={selectGroup}
        open={openDeleteGroupModal}
        handleClose={() => setOpenDeleteGroupModal(false)}
        selectedGroup={selectedGroup}
        handleDeleteGroup={handleDeleteGroup}
      />
      <EditGroupModal
        selectGroup={selectGroup}
        open={openEditGroupModal}
        handleClose={() => setOpenEditGroupModal(false)}
        selectedGroup={selectedGroup}
        handleEditGroup={handleEditGroup}
      />
      <Dropdown
        id="group-more-option-button"
        open={Boolean(selectedGroup) && openMoreOption}
        anchorRef={anchorEl}
        handleClose={handleCloseMoreOption}
        itemList={optionList}
      />
    </div>
  );
};

export default memo(withStyles(styles)(GroupsView));
