import React, { memo, useEffect } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { ActionModal } from '../../../components/Modal';
import { Typography } from '../../../components/Typography';

const styles = (theme: Theme) => {
  return createStyles({
    customerCard: {},
    right: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

export interface IDemoteSubadminModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  selectedCustomer: any;
  handleClose?: any;
  handleDemoteSubadmin: (id: string) => void;
  selectCustomer: any;
}

const DemoteSubadminModal = ({
  classes,
  open,
  selectedCustomer,
  handleClose,
  handleDemoteSubadmin,
  selectCustomer,
}: IDemoteSubadminModalProps) => {
  useEffect(() => {
    handleClose();
  }, [selectCustomer.loading]);

  return (
    <ActionModal
      modalTitle="Do you want to demote this subadmin?"
      open={open}
      handleClose={handleClose}
      actinButtonLabel="Demote"
      actinButtonOnClick={() => handleDemoteSubadmin(selectedCustomer?._id)}
    >
      <Grid container className={classes.customerCard}>
        <Grid item xs={1}>
          <PersonOutlineOutlinedIcon color="primary" fontSize="large" />
        </Grid>
        <Grid item className={classes.right}>
          <Typography variant="body1" color="primary">
            <b>{selectedCustomer?.profile?.name || 'Not available'}</b>
          </Typography>
          <Typography variant="body2" color="secondary">
            {selectedCustomer?.email || 'Not available'}
          </Typography>
        </Grid>
      </Grid>
    </ActionModal>
  );
};

export default memo(withStyles(styles)(DemoteSubadminModal));
