import api from '../../app/axios';

export const fetchAllDevicesApi = () => {
  return api.get('device/read');
};

export const assignDeviceApi = (data: any) => {
  return api.post(`device/assign`, data);
};

export const unassignDeviceApi = (data: any) => {
  return api.post(`device/unassign`, data);
};
