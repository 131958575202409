import React, { memo, useState, useEffect } from 'react';
import moment from 'moment';
import { Grid, MenuItem, Select, Theme } from '@material-ui/core';
// import clsx from 'clsx';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { ActionModal } from '../../../components/Modal';
import { TextField } from '../../../components/TextField';
import { TimePicker } from '../../../components/TimePicker';
import { Button } from '../../../components/Button';
import { generateRoomWithoutSeparator } from '../../../helper/random';
import { isLowercaseAlphanumeric } from '../../../helper/validation';
import { replaceRoomNameWithTitle } from '../../../app/config';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    datePicker: {
      margin: theme.spacing(2, 0),
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    timePicker: {
      margin: theme.spacing(1, 0),
      '& .MuiInputBase-root': {
        color: theme.palette.secondary.main,
      },
      '& .MuiInput-underline:before': {
        borderColor: theme.palette.secondary.main,
      },
    },
    startTime: {
      marginRight: theme.spacing(1),
    },
    endTime: {
      marginLeft: theme.spacing(1),
    },
    select: {
      width: '100%',
      color: '#989898',
      margin: '10px 0',
      '& em': {
        fontStyle: 'unset !important',
      },
    },
  });
};

export interface IScheduleMeetingModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  isEditMeeting: boolean;
  meeting: any;
  groups: any;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  saveMeetingButtonClick: (data: any) => void;
}

const ScheduleMeetingModal = ({
  classes,
  open,
  isEditMeeting,
  meeting,
  groups,
  handleClose,
  saveMeetingButtonClick,
}: IScheduleMeetingModalProps) => {
  const [meetingName, setMeetingName] = useState({ value: '', error: '' });
  const [startTime, setStartTime] = useState({
    value: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
    error: '',
  });
  const endDate = new Date();
  endDate.setTime(endDate.getTime() + 60 * 60 * 1000);
  const [endTime, setEndTime] = useState({
    value: moment(endDate).format('YYYY-MM-DDTHH:mm'),
    error: '',
  });
  const [participants, setParticipants] = useState({ value: '', error: '' });
  const [location, setLocation] = useState({ value: '', error: '' });
  const [groupId, setGroupId] = useState({ value: '', error: '' });

  useEffect(() => {
    if (isEditMeeting) {
      const startTimeError =
        new Date() > new Date(meeting?.beginTime)
          ? 'Enter Valid Date/Time'
          : '';
      const endTimeError =
        new Date() > new Date(meeting?.endTime) ? 'Enter Valid Date/Time' : '';

      setMeetingName({ value: meeting?.name, error: '' });
      setLocation({ value: meeting?.location, error: '' });
      setStartTime({
        value: moment(meeting?.beginTime).format('YYYY-MM-DDTHH:mm'),
        error: startTimeError,
      });
      setEndTime({
        value: moment(meeting?.endTime).format('YYYY-MM-DDTHH:mm'),
        error: endTimeError,
      });
      setParticipants({ value: meeting?.participantCount, error: '' });
      setGroupId({ value: meeting?.groupId, error: '' });
    }
  }, [isEditMeeting]);

  const _handleNameChange = (e: { target: { value: any } }) => {
    if (replaceRoomNameWithTitle && isLowercaseAlphanumeric(e.target.value)) {
      setMeetingName({ value: e.target.value, error: '' });
      setLocation({ value: e.target.value, error: '' });
    } else if (!replaceRoomNameWithTitle) {
      setMeetingName({ value: e.target.value, error: '' });
    }
  };

  const _handleLocationChange = (e: { target: { value: any } }) => {
    if (isLowercaseAlphanumeric(e.target.value))
      setLocation({ value: e.target.value, error: '' });
  };

  const _generateRoomName = () => {
    const roomName = generateRoomWithoutSeparator();
    setLocation({ value: roomName, error: '' });
  };

  const _handleStartTimeChange = (e: any) => {
    if (new Date() <= new Date(e.target.value)) {
      setStartTime({ value: e.target.value, error: '' });
      const date = new Date(e.target.value);
      date.setTime(date.getTime() + 60 * 60 * 1000); // set end time 1 hour after start time
      setEndTime({ value: moment(date).format('YYYY-MM-DDTHH:mm'), error: '' });
    } else
      setStartTime({ value: e.target.value, error: 'Enter Valid Date/Time' });
  };

  const _handleEndTimeChange = (e: any) => {
    if (new Date() <= new Date(e.target.value))
      setEndTime({ value: e.target.value, error: '' });
    else setEndTime({ value: e.target.value, error: 'Enter Valid Date/Time' });
  };

  const _handleParticipantsChange = (e: any) => {
    setParticipants({ value: e.target.value, error: '' });
  };

  const _handleGroupChange = (e: { target: { value: any } }) => {
    setGroupId({ value: e.target.value, error: '' });
  };

  const _validate = () => {
    let isValidate = true;
    if (!meetingName.value) {
      isValidate = false;
      setMeetingName({ ...meetingName, error: 'Meeting Name is Required' });
    }

    if (startTime.error || endTime.error) isValidate = false;

    if (new Date(startTime.value) > new Date(endTime.value)) {
      isValidate = false;
      setEndTime({ ...endTime, error: 'End time cannot be before begin time' });
    }

    if (!groupId.value) {
      isValidate = false;
      setGroupId({ ...groupId, error: 'groupId is Required' });
    }

    return isValidate;
  };

  const _saveMeetingButtonClick = () => {
    if (_validate()) {
      let data: any = {
        name: meetingName.value,
        beginTime: new Date(startTime.value),
        endTime: new Date(endTime.value),
        participantCount: Number.parseInt(participants.value, 10),
        location: location.value,
        groupId: groupId.value,
      };

      if (isEditMeeting) data = { ...data, id: meeting?._id };

      saveMeetingButtonClick(data);
    }
  };

  return (
    <ActionModal
      modalTitle="Schedule a meeting"
      modalDescription="Share this meeting link with others to start your meeting"
      id="schedule-meeting-modal"
      open={open}
      isSingleButton
      handleClose={handleClose}
      actinButtonOnClick={_saveMeetingButtonClick}
      actinButtonLabel="Save Meeting"
    >
      <TextField
        placeholder="Add meeting title / topic"
        onChange={_handleNameChange}
        value={meetingName.value}
        required
        error={Boolean(meetingName.error)}
        helperText={meetingName.error}
      />
      {!replaceRoomNameWithTitle && (
        <TextField
          placeholder="Add room name"
          onChange={_handleLocationChange}
          value={location.value}
          error={Boolean(location.error)}
          helperText={location.error}
          disabled={isEditMeeting}
        />
      )}
      {!replaceRoomNameWithTitle && !isEditMeeting && (
        <Button
          color="primary"
          id="auto generate meeting-room"
          variant="text"
          label="Or generate meeting name"
          onClick={_generateRoomName}
          // customStyles={classes.tableActionButton}
        />
      )}
      <Grid container className={classes.timePicker}>
        <Grid item xs={6}>
          <TimePicker
            customStyles={classes.startTime}
            label="Start Time"
            type="datetime-local"
            id="schedule-meeting-start-time"
            onChange={(e) => _handleStartTimeChange(e)}
            value={startTime.value}
            error={Boolean(startTime.error)}
            helperText={startTime.error}
          />
        </Grid>
        <Grid item xs={6}>
          <TimePicker
            customStyles={classes.endTime}
            label="End Time"
            type="datetime-local"
            id="schedule-meeting-end-time"
            onChange={(e) => _handleEndTimeChange(e)}
            value={endTime.value}
            error={Boolean(endTime.error)}
            helperText={endTime.error}
          />
        </Grid>
      </Grid>
      <Select
        value={groupId.value}
        onChange={_handleGroupChange}
        labelId="schedule-meeting-select-label"
        id="schedule-meeting-select"
        label="Group"
        className={classes.select}
        error={Boolean(groupId.error)}
      >
        {groups.map((data: any) => {
          return (
            <MenuItem value={data._id}>
              <em>{data.name}</em>
            </MenuItem>
          );
        })}
      </Select>

      <TextField
        placeholder="Number of participants"
        onChange={_handleParticipantsChange}
        value={participants.value}
        type="number"
      />
    </ActionModal>
  );
};

export default memo(withStyles(styles)(ScheduleMeetingModal));
