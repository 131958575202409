import api from '../../app/axios';

export const fetchAllUsersApi = () => {
  return api.get('users');
};

export const addUserApi = (data: any) => {
  return api.post(`users`, data);
};

export const deleteUserApi = (id: string) => {
  return api.delete(`users/admin/${id}`);
};

export const resetUserPasswordApi = (data: any) => {
  const { _id, password } = data;
  return api.post(`users/admin/${_id}/password`, { password });
};
