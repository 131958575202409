import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  scheduleMeetingApi,
  editMeetingApi,
  instantMeetingApi,
} from './scheduleMeeting.api';
import { fetchAllMeetingsAsync } from '../meetings/meetings.slice';
import { actions as appActions } from '../app/app.slice';

export interface IScheduleMeetingsState {
  data: any;
  isScheduleModalOpen: boolean;
  isInstantMeetingModalOpen: boolean;
  isEditMeeting: boolean;
  loading: boolean;
  success: boolean;
  error: any;
}

const initialState: IScheduleMeetingsState = {
  data: null,
  isScheduleModalOpen: false,
  isInstantMeetingModalOpen: false,
  isEditMeeting: false,
  loading: false,
  success: false,
  error: null,
};

export const scheduleMeetingAsync = createAsyncThunk(
  'createScheduleMeeting',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await scheduleMeetingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(fetchAllMeetingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully created scheduled meeting',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const instantMeetingAsync = createAsyncThunk(
  'createInstantMeeting',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await instantMeetingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(fetchAllMeetingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: 'successfully created instant meeting',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editMeetingAsync = createAsyncThunk(
  'edit',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await editMeetingApi(data);

      // fetch all the meetings after schedule a new meeting
      dispatch(fetchAllMeetingsAsync(null));
      dispatch(
        appActions.triggerAlert({
          type: 'success',
          childern: ' successfully edit the meeting',
        })
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        appActions.triggerAlert({
          type: 'error',
          childern: error?.response?.data?.error || 'Error occurred',
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const scheduleMeetingSlice = createSlice({
  name: 'scheduleMeeting',
  initialState,
  reducers: {
    openScheduleModal(state) {
      state.isScheduleModalOpen = true;
    },
    closeScheduleModal(state) {
      state.isScheduleModalOpen = false;
    },
    openInstantMeetingModal(state) {
      state.isInstantMeetingModalOpen = true;
    },
    closeInstantMeetingModal(state) {
      state.isInstantMeetingModalOpen = false;
    },
    openEditMeetingModal(state, action) {
      state.isEditMeeting = true;
      state.isScheduleModalOpen = true;
      state.data = action.payload;
    },
    closeEditMeetingModal(state) {
      state.isEditMeeting = false;
      state.isScheduleModalOpen = false;
    },
    resetScheduleMeeting(state) {
      state.loading = false;
      state.data = null;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // schedule a new meeting
    builder.addCase(scheduleMeetingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = null;
      state.success = false;
      state.error = null;
    });
    builder.addCase(scheduleMeetingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(scheduleMeetingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });

    // create a instant meeting
    builder.addCase(instantMeetingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = null;
      state.success = false;
      state.error = null;
    });
    builder.addCase(instantMeetingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(instantMeetingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });

    // edit meeting
    builder.addCase(editMeetingAsync.pending, (state, action) => {
      state.loading = true;
      state.data = null;
      state.success = false;
      state.error = null;
    });
    builder.addCase(editMeetingAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.success = true;
    });
    builder.addCase(editMeetingAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });
  },
});

export const { actions } = scheduleMeetingSlice;

export const selectSchesuleMeeting = (state: RootState) =>
  state.scheduleMeeting;

export default scheduleMeetingSlice.reducer;
